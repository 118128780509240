<template>
  <div class="coupon">
    <div class="search">
      <el-button type="success" @click="gotoAdd(0)" size="small">新增</el-button>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in list" :key="index">
        <!-- <div class="sort">买单送券</div> -->
        <couponCus :item="item"></couponCus>
        <div class="btn">
          <el-button size="mini" type="warning" plain @click="gotoAdd(item.id)">修改</el-button>
          <el-popconfirm style="margin-left: 10px;" title="是否确认删除" @confirm="confirmDel(item)">
            <el-button slot="reference" size="mini" type="danger" plain>删除</el-button>
          </el-popconfirm>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { couponList, couponDel } from "@/network/request";
import couponCus from '@/components/coupon-cus.vue';
export default {
  components: { couponCus },
  data() {
    return {
      form: {},
      list: [],
      getArg: {
        page: 1,
        size: 50,
        content: {
          shopId: null,
          classify: null,
        }
      },
      total: 0,
    }
  },
  methods: {
    confirmDel(v) {
      couponDel(this.getArg.content.shopId, v.id).then(res => {
        let data = res.data
        if (data.code == 200) {
          this.$message.success(data.message)
        } else {
          this.$message.error(data.message)
        }
        this.getData()
      })
    },
    getData() {
      couponList(this.getArg).then(res => {
        let data = res.data
        if (data.code == 200) {
          this.list = data.data.data
          this.total = data.data.total
        }
      })
    },
    gotoAdd(v) {
      this.$router.push("/couponAdd/" + v)
    }
  },
  created() {
    this.getArg.content.shopId = parseInt(window.localStorage.getItem("shopId"));
    this.getData()
  }
}
</script>
  
<style lang="less" scoped>
.coupon {
  background-color: #fff;
  padding: 20px;
  line-height: 1;

  .content {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
      // width: 300px;
      padding: 20px;
      margin-right: 20px;
      margin-bottom: 20px;

      .sort {
        margin-bottom: 10px;
      }

      .btn {
        margin-top: 10px;
        text-align: right;
      }

    }
  }
}
</style>