<template>
    <div class="data-analysis">
        <div class="search">
            <span>时间：</span>
            <el-date-picker v-model="timeArr" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" style="margin: 0 20px;">
            </el-date-picker>
            <el-button type="primary">查询</el-button>
        </div>
        <div class="content">
            <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_1.png" class="imgs" alt="">
                    <span>买单送券</span>
                </div>
                <div class="cnt">
                    <div class="items">总送券人次：<el-input v-model="info.num1" class="ipt-num" />次 </div>
                    <div class="items">买单送券用券人次 ：<el-input v-model="info.num2" class="ipt-num" />次 </div>
                    <div class="items">带动消费：{{(info.num13/100).toFixed(2)}}元</div>
                </div>
            </div>
            <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_8.png" class="imgs" alt="">
                    <span>异业发券</span>
                </div>
                <div class="cnt">
                    <div class="items">异业领券人次：<el-input v-model="info.num5" class="ipt-num" />次 </div>
                    <div class="items">异业领券用券人次：<el-input v-model="info.num6" class="ipt-num" />次 </div>
                    <div class="items">带动消费：{{(info.num14/100).toFixed(2)}}元</div>
                </div>
            </div>
            <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_9.png" class="imgs" alt="">
                    <span>买单抽奖</span>
                </div>
                <div class="cnt">
                    <div class="items">抽奖人次：<el-input v-model="info.num3" class="ipt-num" />次 </div>
                    <div class="items">抽奖用券人次：<el-input v-model="info.num4" class="ipt-num" />次</div>
                    <div class="items">带动消费：{{(info.num15/100).toFixed(2)}}元</div>
                </div>
            </div>
            <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_4.png" class="imgs" alt="">
                    <span>购买券包</span>
                </div>
                <div class="cnt">
                    <div class="items">购买人次：<el-input  v-model="info.num7" class="ipt-num" />次</div>
                    <div class="items">用券人次：<el-input v-model="info.num8" class="ipt-num" />次 </div>
                    <div class="items">带动消费：{{(info.num16/100).toFixed(2)}}元</div>
                </div>
            </div>
            <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_5.png" class="imgs" alt="">
                    <span>定向发券</span>
                </div>
                <div class="cnt">
                    <div class="items">发券人次：<el-input v-model="info.num9" class="ipt-num" />次 </div>
                    <div class="items">用券人次：<el-input v-model="info.num10" class="ipt-num" />次</div>
                    <div class="items">带动消费：{{(info.num17/100).toFixed(2)}}元</div>
                </div>
            </div>
            <!-- <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_6.png" class="imgs" alt="">
                    <span>积分抽奖发券</span>
                </div>
                <div class="cnt">
                    <div class="items">发券数量：<el-input  class="ipt-num"/>张</div>
                    <div class="items">用券人数：<el-input  class="ipt-num"/>人 </div>
                    <div class="items">带动消费：<el-input  class="ipt-num"/>元</div>
                </div>
            </div> -->
            <div class="item">
                <div class="title">
                    <img src="../assets/images/icon_coupon_7.png" class="imgs" alt="">
                    <span>集卡福利</span>
                </div>
                <div class="cnt">
                    <div class="items">发券人次：<el-input v-model="info.num11" class="ipt-num" />次</div>
                    <div class="items">用券人次：<el-input v-model="info.num12" class="ipt-num" />次 </div>
                    <div class="items">带动消费：{{(info.num18/100).toFixed(2)}}元</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { analysis } from "@/network/request";
export default {
    data() {
        return {
            timeArr: null,
            getArg: {
                shopId: null,
                timeStart: null,
                timeEnd: null,
            },
            info:{}
        }
    },
    methods: {
        getData() {
            if (this.timeArr == null) {
                this.getArg.timeStart = null
                this.getArg.timeEnd = null
            } else {
                this.getArg.timeStart = new Date(this.timeArr[0]).getTime()
                this.getArg.timeEnd = new Date(this.timeArr[1]).getTime()
            }
            analysis(this.getArg).then(res=>{
                let data = res.data
                if(data.code==200){
                    this.info=data.data
                }
            })
        }
    },
    created() {
        this.getArg.shopId = parseInt(window.localStorage.getItem("shopId"))
        this.getData()
    }
}
</script>

<style lang="less" scoped>
.data-analysis {
    background-color: #fff;
    padding: 20px;

    .ipt-num {
        width: 80px;
        margin-right: 6px;
    }

    .content {
        margin-top: 20px;

        .item {
            margin-bottom: 20px;

            .title {
                padding: 10px 20px;
                background-color: #eee;
                display: flex;
                flex-direction: row;
                align-items: center;

                .imgs {
                    width: 30px;
                    margin-right: 4px;
                }
            }

            .cnt {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .items {
                    width: 400px;
                }
            }
        }
    }
}
</style>