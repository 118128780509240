import Vue from 'vue'
import App from './App.vue'
import router from './router'

import {
  button,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Menu,
  MenuItem,
  Submenu,
  Table,
  TableColumn,
  Popconfirm,
  Input,
  Select,
  Option,
  Radio,
  RadioGroup,
  Pagination,
  Message,
  Notification,
  Dialog,
  Tag,
  Drawer,
  Steps,
  Step,
  Cascader,
  Upload,
  Avatar,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DatePicker,
  Popover,
  CheckboxGroup,
  Checkbox,
  Loading,
  Form,
  FormItem,
  InputNumber,
  Image,
  MessageBox,
  Carousel,
  CarouselItem,
  Col,
  TimePicker,
  Tabs,
  TabPane
} from 'element-ui'
Vue.use(button)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Popconfirm)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Drawer)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Cascader)
Vue.use(Upload)
Vue.use(Avatar)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(DatePicker)
Vue.use(Popover)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(InputNumber)
Vue.use(Image)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Col)
Vue.use(TimePicker)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Loading)

Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


Vue.filter("timestamp2str", (timestamp) => {
	const date = new Date(timestamp);
	const year = date.getFullYear();
	const month = ('0' + (date.getMonth() + 1)).slice(-2);
	const day = ('0' + date.getDate()).slice(-2);
	const hours = ('0' + date.getHours()).slice(-2);
	const minutes = ('0' + date.getMinutes()).slice(-2);
	const seconds = ('0' + date.getSeconds()).slice(-2);
	const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
	return formattedDate;
})

Vue.filter("timeStrSubstring", (timeStr) => {
	return timeStr.substring(0,10);
})