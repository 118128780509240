<template>
  <div class="home">
    <el-container>
      <el-header height="50px">
        <div class="logo">{{ shopName }}</div>
        <div class="right">
          <el-avatar icon="el-icon-user-solid" size="small" color="red"></el-avatar>
          <el-dropdown style="margin-left: 10px" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">切换门店</el-dropdown-item>
              <el-dropdown-item command="2">修改密码</el-dropdown-item>
              <el-dropdown-item command="3" @click="handlerLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>

      <el-container>
        <el-aside width="200px">
          <el-menu :default-active="defaultMenu" class="el-menu-vertical-demo" background-color="#384150"
            text-color="#ffffff" active-text-color="#409EFF" @select="selectMenu">
            <el-menu-item index="1" @click="gotoPage('/index')" v-if="permission.includes(1)">
              <i class="el-icon-menu"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="2" @click="gotoPage('/shop')" v-if="permission.includes(2)">
              <i class="el-icon-menu"></i>
              <span slot="title">商户信息</span>
            </el-menu-item>
            <el-menu-item index="3" @click="gotoPage('/memberVip')" v-if="permission.includes(3)">
              <i class="el-icon-menu"></i>
              <span slot="title">客户管理</span>
            </el-menu-item>

            <el-submenu index="4" v-if="permission.includes(4)">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span slot="title">营销管理</span>
              </template>
              <el-menu-item index="4-1" @click="gotoPage('/activityList')"
                v-if="permission.includes(5)">活动管理</el-menu-item>
              <el-menu-item index="4-2" @click="gotoPage('/activity')" v-if="permission.includes(6)">添加活动</el-menu-item>
              <el-menu-item index="4-3" @click="gotoPage('/coupon')" v-if="permission.includes(7)">优惠券管理</el-menu-item>
              <el-menu-item index="4-4" @click="gotoPage('/payAdv')" v-if="permission.includes(8)">广告管理</el-menu-item>
              <el-menu-item index="4-5" @click="gotoPage('/partner')" v-if="permission.includes(8)">合作商户</el-menu-item>
            </el-submenu>
            <el-menu-item index="5" @click="gotoPage('/statement')" v-if="permission.includes(9)">
              <i class="el-icon-menu"></i>
              <span slot="title">报表中心</span>
            </el-menu-item>
            <el-menu-item index="6" @click="gotoPage('/dataAnalysis')" v-if="permission.includes(10)">
              <i class="el-icon-menu"></i>
              <span slot="title">数据分析</span>
            </el-menu-item>

            <el-submenu index="7" v-if="permission.includes(11)">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span slot="title">系统管理</span>
              </template>
              <el-menu-item index="7-1" @click="gotoPage('/employee')" v-if="permission.includes(12)">员工管理</el-menu-item>
              <el-menu-item index="7-2" @click="gotoPage('/role')" v-if="permission.includes(13)">角色管理</el-menu-item>
              <el-menu-item index="7-3" @click="gotoPage('/receipt')" v-if="permission.includes(13)">小票设置</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog title="切换店铺" :visible.sync="editVisible" width="60%">
      <div class="edit-content">
        <el-radio-group v-model="shopId">
          <el-radio :label="item.id" v-for="(item) in shopList" :key="item.id">{{ item.shopName }}</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <div style="display: none;">
      <NoteTemp :item="info" ref="temp"></NoteTemp>
    </div>
    <el-dialog title="修改密码" :visible.sync="show" width="30%">
      <div class="content">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="新密码" prop="newPass">
            <el-input v-model="form.newPass" placeholder="8-16位密码，区分大小写"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="rePass">
            <el-input v-model="form.rePass"></el-input>
          </el-form-item>
        </el-form>
        <div class="tips">密码必须包含数字，字母，长度6-10位！</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="editHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getLodop } from '@/assets/js/LodopFuncs'
import NoteTemp from "@/components/NoteTemp.vue";
import { changeShop, getPrintInfo, editPass } from "@/network/request";
import mqtt from "mqtt";
export default {
  components: {
    NoteTemp
  },
  data() {
    return {
      show: false,
      editVisible: false,
      defaultMenu: "1",
      shopName: "",
      username: "",
      permission: [],
      shopList: [],
      shopId: "",
      connection: {
        host: 'www.beect.cn',
        port: 8083,
        endpoint: '/mqtt',
        clean: true,
        connectTimeout: 4000,
        reconnectPeriod: 4000,
        clientId: '',
        username: '',
        password: '',
      },
      form: {},
      subscribeStr: "",
      client: null,
      info: {},
      LODOP: null,
      printerInfo: {
        index: null,
        pageSize: "",
      }
    };
  },
  mounted() {
    this.shopList = JSON.parse(window.localStorage.getItem("shopList"));
    this.permission = JSON.parse(window.localStorage.getItem("permission"));
    this.defaultMenu = window.localStorage.getItem("currentIndex");
    this.shopId = window.localStorage.getItem("shopId");
    this.username = JSON.parse(window.localStorage.getItem("userinfo")).nickname;
    let tempPrint = JSON.parse(window.localStorage.getItem("printerInfo"))
    if (tempPrint != undefined) {
      this.printerInfo = tempPrint
    }
    if (this.shopId != null) {
      for (let i = 0; i < this.shopList.length; i++) {
        if (this.shopId == this.shopList[i].id) {
          this.shopName = this.shopList[i].shopName
        }
      }
    } else {
      this.shopName = this.shopList[0].shopName
      this.shopId = this.shopList[0].shopId
    }
    setTimeout(() => {
      this.LODOP = getLodop()
      this.getConnection()
    }, 2000)
  },
  methods: {
    validatorPwd(value) {
      return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/.test(value);
    },
    editHandler() {
      if (this.form.newPass != this.form.rePass) {
        this.$message.warning("两次输入密码不一致！");
        return
      }
      if (this.validatorPwd(this.form.newPass)) {
        editPass(this.form).then((res) => {
          let data = res.data;
          if (data.code==200) {
            this.show = false;
            this.$message.success(data.message);
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("shopList");
            window.localStorage.removeItem("shopId");
            window.localStorage.removeItem("userinfo");
            window.localStorage.removeItem("permission");
            window.localStorage.setItem("currentIndex", "1");
            this.$router.replace("/login");
          } else {
            this.$message.error(data.message);
          }
        });
      } else {
        this.$message.warning("密码必须包含数字，字母，长度6-10位！");
      }
    },
    getPayInfo(orderId) {
      getPrintInfo(orderId).then(res => {
        let data = res.data
        if (data.code==200) {
          this.info = data.data
          // console.log(this.info);
          setTimeout(() => {
            this.print()
          }, 500)
        }
      })
    },
    getConnection() {
      this.connection.clientId = this.shopId + "_web_" + Math.round(Math.random() * 1000)
      this.connection.username = this.shopId + ""
      this.connection.password = this.shopId + ""
      this.subscribeStr = "notice/" + this.shopId + "_web"
      const { host, port, endpoint, ...options } = this.connection
      const connectUrl = `ws://${host}:${port}${endpoint}`
      try {
        this.client = mqtt.connect(connectUrl, options)
        this.client.on('connect', (e) => {
          console.log('连接成功', e)
        })

        //重连提醒
        this.client.on('reconnect', (error) => {
          console.log('正在重连', error)
        })
        //连接失败提醒
        this.client.on('error', (error) => {
          console.log('连接失败', error)
        })

        this.client.on('message', (topic, message) => {
          if (topic == this.subscribeStr) {
            const data = JSON.stringify(`${message}`) + ""
            this.getPayInfo(data.substring(1, data.length - 1))
          }
        })

        this.client.subscribe(this.subscribeStr, { qos: 1 }, (err) => {
          if (!err) {
            console.log(`主题为：“${this.subscribeStr}” 的消息订阅成功`)
          } else {
            console.log('消息订阅失败')
          }
        })
      } catch (error) {
        console.log('mqtt.connect error', error)
      }
    },

    print() {
      let tempPrint = JSON.parse(window.localStorage.getItem("printerInfo"))
      if (tempPrint != undefined) {
        this.printerInfo = tempPrint
      }
      // console.log(this.printerInfo);
      this.LODOP.PRINT_INIT("打印任务名");
      let pageWidth = parseInt(this.printerInfo.pageSize.split('(')[0] + "0")
      this.LODOP.SET_PRINT_STYLEA("FontSize", "12");
      this.LODOP.SET_PRINT_STYLEA("FontName", "黑体");
      this.LODOP.SET_PRINT_PAGESIZE(3, pageWidth + "mm", 0, "")
      this.LODOP.ADD_PRINT_TEXT(0, 0, "100%", "6mm", `订单号：`)
      this.LODOP.ADD_PRINT_TEXT(0, "11mm", "100%", "6mm", `${this.info.orderId}`)
      this.LODOP.ADD_PRINT_TEXT("5mm", 0, "100%", "6mm", `订单时间：`)
      this.LODOP.ADD_PRINT_TEXT("5mm", "16mm", "100%", "6mm", `${this.info.timeCreate}`)
      if (pageWidth == 58) {
        this.LODOP.SET_PRINT_STYLEA(2, "FontSize", "8");
        this.LODOP.SET_PRINT_STYLEA(4, "FontSize", "8");
      }
      this.LODOP.ADD_PRINT_TEXT("10mm", 0, "100%", "6mm", `账单金额：${this.info.amountTotal}元`)
      this.LODOP.ADD_PRINT_TEXT("15mm", 0, "100%", "6mm", `优惠金额：${this.info.amountDiscount}元`)
      this.LODOP.ADD_PRINT_TEXT("20mm", 0, "100%", "6mm", `实收金额：${this.info.amountReal}元`)
      if (this.info.couponInfo) {
        this.LODOP.ADD_PRINT_TEXT("25mm", 0, "100%", "12mm", `使用优惠：${this.info.couponInfo}`)
      }
      this.LODOP.SET_PRINTER_INDEXA(this.printerInfo.index)
      this.LODOP.PRINT();
    },

    editConfirm() {

      this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      changeShop(this.shopId).then(res => {
        let data = res.data
        if (data.code==200) {
          window.localStorage.setItem(
            "token",
            data.data.token
          );
          this.permission = data.data.permission
          window.localStorage.setItem(
            "permission",
            JSON.stringify(data.data.permission)
          );

          window.localStorage.setItem(
            "shopId",
            this.shopId
          );
          for (let i = 0; i < this.shopList.length; i++) {
            if (this.shopId == this.shopList[i].id) {
              this.shopName = this.shopList[i].shopName
            }
          }

          this.defaultMenu = "1"
          window.localStorage.setItem("currentIndex", "1");
          this.$router.push("/")
          this.$router.go(0)
        }
        this.editVisible = false
      })


    },
    gotoPage(v) {
      this.$router.push(v);
    },
    selectMenu(m) {
      window.localStorage.setItem("currentIndex", m);
    },
    handleCommand(v) {
      if (v == 1) {
        this.editVisible = true
      } else if (v == 2) {
        this.show = true
      } else if (v == 3) {
        this.handlerLogout();
      }
    },
    handlerLogout() {
      // console.log("退出登录");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("shopList");
      window.localStorage.removeItem("shopId");
      window.localStorage.removeItem("userinfo");
      window.localStorage.removeItem("permission");
      window.localStorage.setItem("currentIndex", "1");
      this.$router.replace("/login");
    },
  },
  beforeDestroy() {
    this.client.end()
    this.client = null
    console.log('已经与mqtt客户端断开连接')
  },
};
</script>

<style lang="less">
.el-aside {
  background-color: #384150;
  height: calc(100vh - 50px);
}

.el-menu {
  border: none;
}

.el-header {
  min-width: 1400px;
  background-color: #384150;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .logo {
    // background-color: #f24b7f;
    text-align: center;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    font-weight: bold;
    color: aliceblue;
  }

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .el-icon-user-solid:before {
    color: #409eff;
  }

  .el-avatar {
    background-color: #ffffff;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }
}

.el-main {
  // padding:0;
  padding-left: 20px;
  background-color: #f8f8f8;
  height: calc(100vh - 50px);
  overflow: auto;
  box-sizing: border-box;
  // border: 1px solid #333;
  min-width: 1200px;
}

.tips {
  text-align: right;
  color: red;
  font-size: 12px;
}
</style>
