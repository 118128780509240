<template>
  <div class="activity">
    <div class="content">
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_1.png" alt="">
        <div class="title">买单送券</div>
        <div class="btn" @click="gotoSet(1)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_8.png" alt="">
        <div class="title">买单抽奖</div>
        <div class="btn" @click="gotoSet(2)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_9.png" alt="">
        <div class="title">异业发券</div>
        <div class="btn" @click="gotoSet(3)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_4.png" alt="">
        <div class="title">购买券包</div>
        <div class="btn" @click="gotoSet(4)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_5.png" alt="">
        <div class="title">定向发券</div>
        <div class="btn" @click="goto('/memberVip')">立即设置</div>
      </div>
      <!-- <div class="item in-active">
        <img class="icon" src="../../assets/images/icon_coupon_6.png" alt="">
        <div class="title">消费满减</div>
        <div class="btn">立即设置</div>
      </div>
      <div class="item in-active">
        <img class="icon" src="../../assets/images/icon_coupon_7.png" alt="">
        <div class="title">积分抽奖</div>
        <div class="btn">立即设置</div>
      </div> -->
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_2.png" alt="">
        <div class="title">生日送券</div>
        <div class="btn" @click="gotoSet(8)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_3.png" alt="">
        <div class="title">集卡福利</div>
        <div class="btn" @click="gotoSet(9)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_6.png" alt="">
        <div class="title">霸王餐</div>
        <div class="btn" @click="gotoSet(10)">立即设置</div>
      </div>
      <div class="item">
        <img class="icon" src="../../assets/images/icon_coupon_1.png" alt="">
        <div class="title">团购</div>
        <div class="btn" @click="gotoSet(11)">立即设置</div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  methods: {
    goto(path) {
      this.$router.push(path)
    },
    gotoSet(v) {
      this.$router.push("/activitySet/" + v+"/0")
    }
  }
}
</script>
  
<style lang="less" scoped>
.activity {
  background-color: #fff;
  padding: 20px;

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item {
      width: 30%;
      height: 190px;
      border-radius: 10px;
      padding-top: 20px;
      box-sizing: border-box;
      border: 1px solid #409eff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-right: 30px;

      .icon {
        width: 80px;
        height: 80px;
      }

      .title {
        font-size: 20px;
      }

      .btn {
        width: 100%;
        height: 32px;
        background-color: #409eff;
        border-radius: 0 0 10px 10px;
        color: #fff;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }
    }

    .in-active {
      .btn {
        background-color: #999;
      }
    }
  }

}
</style>