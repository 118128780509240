import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Statement from '../views/Statement'
import DataAnalysis from '../views/DataAnalysis'
import Index from '../views/index/Index.vue'
import Register from '../views/Register.vue'
import Shop from '../views/shop/Shop.vue'
import ShopAdd from '../views/shop/Add.vue'
import AddSubject from '../views/shop/AddSubject.vue'
import ZfbAdd from '../views/shop/ZfbAdd.vue'
import MemberVip from '../views/MemberVip.vue'
import Activity from '../views//marketing/Activity'
import Coupon from '../views//marketing/Coupon'
import ActivityList from '../views//marketing/ActivityList'
import CouponAdd from '../views/marketing/CouponAdd'
import ActivitySet from '../views/marketing/Set'
import ActivityDetails from '../views/marketing/ActivityDetails'
import PayAdv from '../views/marketing/PayAdv'
import Partner from '../views/marketing/Partner'
import Employee from '../views/system/Employee'
import Receipt from '../views/system/Receipt'
import Role from '../views/system/Role'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: "/index",
    children: [
      {
        path: '/index',
        name: 'index',
        component: Index,
      }, 
      {
        path: '/shop',
        name: 'shop',
        component: Shop,
      }, 
      {
        path: '/shopAdd',
        name: 'shopAdd',
        component: ShopAdd,
      }, 
      {
        path: '/zfbAdd',
        name: 'zfbAdd',
        component: ZfbAdd,
      }, 
      {
        path: '/addSubject/:id/:subject_type',
        name: 'addSubject',
        component: AddSubject,
      }, 
      {
        path: '/memberVip',
        name: 'memberVip',
        component: MemberVip,
      },
      {
        path: '/activity',
        name: 'activity',
        component: Activity,
      },
      {
        path: '/partner',
        name: 'partner',
        component: Partner,
      },
      {
        path: '/coupon',
        name: 'coupon',
        component: Coupon,
      },
      {
        path: '/activityList',
        name: 'activityList',
        component: ActivityList,
      },
      {
        path: '/activitySet/:type/:id',
        name: 'activitySet',
        component: ActivitySet,
      },
      {
        path: '/activityDetails/:id',
        name: 'activityDetails',
        component: ActivityDetails,
      },
      {
        path: '/couponAdd/:type',
        name: 'couponAdd',
        component: CouponAdd,
      },
      {
        path: '/payAdv',
        name: 'payAdv',
        component: PayAdv,
      },
      {
        path: '/statement',
        name: 'statement',
        component: Statement,
      },
      {
        path: '/dataAnalysis',
        name: 'dataAnalysis',
        component: DataAnalysis,
      },
      {
        path: '/employee',
        name: 'employee',
        component: Employee,
      },
      {
        path: '/role',
        name: 'role',
        component: Role,
      },
      {
        path: '/receipt',
        name: 'receipt',
        component: Receipt,
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  }
  ,
  {
    path: '/register',
    name: 'register',
    component: Register,
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from,next) => {
  if(to.name!="login" && window.localStorage.getItem("token")==null){
    next({name:"login"})
  }
  next()
})

export default router
