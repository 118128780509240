<template>
    <div class="register">
        <div class="form">
            <div class="title">注册</div>
            <el-form ref="registerForm" :rules="rules" :model="form" label-width="100px">
                <el-form-item label="门店名称" prop="shopName">
                    <el-input v-model="form.shopName"></el-input>
                </el-form-item>
                <el-form-item label="行业" prop="industry">
                    <el-cascader v-model="industry" :options="options" @change="industryChange"
                        style="width: 100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="注册账号" prop="account">
                    <el-input v-model="form.account" placeholder="注册账号，请填写手机号"></el-input>
                </el-form-item>
                <el-form-item label="管理员姓名" prop="adminName">
                    <el-input v-model="form.adminName"></el-input>
                </el-form-item>
                <el-form-item label="管理员电话" prop="adminPhone">
                    <el-input v-model="form.adminPhone"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱" prop="adminEmail">
                    <el-input v-model="form.adminEmail"></el-input>
                </el-form-item>
                <el-form-item label="门店地址" prop="shopAddress">
                    <el-input v-model="form.shopAddress"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="loginPwd">
                    <el-input v-model="form.loginPwd" placeholder="密码由8-20位字母+数字+特殊符号组成，区分大小写"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="loginPwdAgain">
                    <el-input v-model="form.loginPwdaAain"></el-input>
                </el-form-item>
                <el-form-item label="手机验证码" prop="code">
                    <div class="code">
                        <el-input v-model="form.code"></el-input>
                        <el-button type="success" style="margin-left: 30px;" @click="getMsg">获取验证码</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="action">
                <el-button type="primary" style="width: 400px;" @click="registerHandler">注册</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import hy from "@/assets/json/hy";
import { registerAdd, getMessage } from "@/network/request";
export default {
    data() {
        return {
            form: {
                shopName: "",
                industry: "",
                account: "",
                adminName: "",
                adminPhone: "",
                adminEmail: "",
                shopAddress: "",
                loginPwd: "",
                code: "",
            },
            industry: [],
            hy: hy,
            options: [],
            rules: {
                shopName: [
                    { required: true, message: '请输入门店名称', trigger: 'blur' }
                ],
                industry: [
                    { required: true, message: '请选择行业', trigger: 'change' }
                ],
                account: [
                    { required: true, message: '请输入注册账号', trigger: 'blur' }
                ],
                adminName: [
                    { required: true, message: '请输入管理员姓名', trigger: 'blur' }
                ],
                adminPhone: [
                    { required: true, message: '请输入管理员电话', trigger: 'blur' },
                    { length: 11, message: '手机号长度为11位', trigger: 'blur' }
                ],
                shopAddress: [
                    { required: true, message: '请输入门店地址', trigger: 'blur' }
                ],
                loginPwd: [
                    { required: true, message: '请输入登录密码', trigger: 'blur' },
                    {
                        validator: function (rule, value, callback) {
                            var m = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#￥%^&*])[\da-zA-Z!@#￥%^&*]{8,20}$/;
                            if (m.test(value) == false) {
                                callback(new Error('密码格式不正确，密码需包含字母数字和特殊符号，长度8-20位'));
                            } else {
                                callback();
                            }
                        }, trigger: 'blur'
                    }
                ],
                loginPwdAgain: [
                    { required: true, message: '请输入确认密码', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入手机验证码', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(res => {
                    console.log(res);
                    this.form.latitude = res.coords.latitude;
                    this.form.longitude = res.coords.longitude;
                }, err => {
                    console.log(err);
                },{
                    enableHighAccuracy:true,
                    maximumAge:3000,
                    timeout:50000
                });
            } else {
                console.log("该浏览器不支持获取地理位置信息。");
            }
        },
        getMsg() {
            getMessage(this.form.adminPhone).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.$message.success(data.message)
                } else {
                    this.$message.error(data.message)
                }
            })
        },
        industryChange(v) {
            this.form.industry = v.join("_")
        },
        registerHandler() {
            console.log(this.form);
            registerAdd(this.form).then(res => {
                console.log(res);
            })
        }
    },
    created() {
        let arr = []
        for (let i = 0; i < this.hy.length; i++) {
            let obj = {}
            obj.label = this.hy[i].big_industry_info.big_industry_name
            obj.value = this.hy[i].big_industry_info.big_industry_name
            obj.children = []
            for (let j = 0; j < this.hy[i].industry_category_list.length; j++) {
                let childObj = {}
                childObj.label = this.hy[i].industry_category_list[j].industry_category_info.category_name
                childObj.value = this.hy[i].industry_category_list[j].industry_category_info.category_name
                obj.children.push(childObj)
            }
            arr.push(obj)
            // console.log(this.hy[i]);
        }
        this.options = arr
        this.getLocation()
    }
}
</script>

<style lang="less" scoped>
.register {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .form {
        width: 50vw;
        margin-bottom: 100px;

        .title {
            text-align: center;
            font-weight: bold;
            font-size: 30px;
            margin: 30px 0;
        }

        .action {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .code {
            display: flex;
            flex-direction: row;
        }
    }
}
</style>