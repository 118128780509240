<template>
    <div class="set">
        <div class="header">{{ title }}</div>
        <div class="title">活动名称</div>
        <div>
            <el-input style="width: 400px;" v-model="form.nickname" placeholder="请输入活动名称" />
        </div>
        <div class="title">活动时间</div>
        <div>
            <el-date-picker type="daterange" style="width: 400px;" v-model="timeArr" range-separator="至"
                start-placeholder="开始日期" @change="timeChange" end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <div class="title" v-if="[3, 4].includes(form.classify)">展示设置</div>
        <div class="display" v-if="[3, 4].includes(form.classify)">
            <div class="display-row">
                <div style="margin-right: 20px;">支付页面</div>
                <el-radio-group v-model="form.showPay" style="display: flex;">
                    <el-radio :label="1">展示</el-radio>
                    <el-radio :label="0">不展示</el-radio>
                </el-radio-group>
            </div>
            <div class="display-row" style="margin-top: 10px;">
                <div style="margin-right: 20px;">其它页面</div>
                <el-radio-group v-model="form.showOther" style="display: flex;">
                    <el-radio :label="1">展示</el-radio>
                    <el-radio :label="0">不展示</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="title" v-if="form.classify == 1">发券类型</div>
        <div v-if="form.classify == 1" class="row">
            <el-radio-group v-model="form.buySendClassify" class="row" @change="buySendChange">
                <el-radio :label="0">倍数返券</el-radio>
                <el-radio :label="1">梯度返券</el-radio>
            </el-radio-group>
        </div>
        <div class="title" v-if="[1, 3, 4, 8, 9, 10, 11].includes(form.classify) && form.buySendClassify != 1">设置优惠券
        </div>
        <div v-if="[1, 3, 4, 8, 9, 10, 11].includes(form.classify) && form.buySendClassify != 1">
            <div>
                <el-button type="success" @click="addCouponVisible = true">新增优惠券</el-button>
                <!-- <el-button type="warning">新增优惠券</el-button> -->
            </div>
            <div class="coupon-table" style="margin-top: 20px;">
                <el-table :data="form.couponList" border style="width: 100%">
                    <el-table-column label="优惠券" align="center">
                        <template slot-scope="v">
                            <div class="coupon-cnt">
                                <div v-for="(item, index) in list" :key="index">
                                    <coupon-cus :item="item" v-if="v.row.couponId == item.id"></coupon-cus>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量(张)" align="center">
                        <template slot-scope="v">
                            <el-input-number v-model="v.row.count" :min="1"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="v">
                            <el-button type="danger" plain @click="cancelCoupon(v.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="title" v-if="form.classify == 1 && form.buySendClassify == 1">设置梯度</div>
        <div v-if="form.classify == 1 && form.buySendClassify == 1">
            <div><el-button type="success" @click="addThresholdVisible = true">新增门槛</el-button></div>
            <div class="coupon-table" style="margin-top: 20px;">
                <el-table :data="form.couponList" border style="width: 100%">
                    <el-table-column prop="thresholdAmount" label="消费门槛(元)" align="center">
                        <template slot-scope="v">
                            <p>{{ (v.row.thresholdAmount / 100).toFixed(2) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠券" align="center">
                        <template slot-scope="v">
                            <div class="coupon-cnt">
                                <!-- <p>{{ v.row.couponId }}</p> -->
                                <div v-for="(item) in list" :key="item.id">
                                    <coupon-cus :item="item" v-if="v.row.couponId == item.id"></coupon-cus>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量(张)" align="center">
                        <template slot-scope="v">
                            <el-input-number v-model="v.row.count"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="v">
                            <el-button type="danger" plain @click="cancelCoupon(v.row)">删除</el-button>
                            <el-button type="primary" plain @click="setCoupon(v)">设置优惠券</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </div>
        <div class="title" v-if="[1, 3, 4, 8, 9].includes(form.classify)">发券设置</div>
        <div v-if="form.classify == 1">
            <div class="row" v-if="form.buySendClassify != 1">当消费满 <el-input class="ipt-num"
                    v-model="tempForm.thresholdAmount" />元时赠送</div>
            <div class="row">
                <el-checkbox v-model="form.useNotSend" :true-label="1" :false-label="0">用券不送券</el-checkbox>
                <el-checkbox v-model="form.decreaseAndSend" :true-label="1" :false-label="0"
                    v-if="form.useNotSend != 1">减券金额后赠送券</el-checkbox>
                <el-checkbox v-model="form.whetherRepeatSend" :true-label="1" :false-label="0"
                    v-if="form.buySendClassify != 1">可重复赠送</el-checkbox>
            </div>
        </div>
        <div class="title" v-if="[10, 11].includes(form.classify)">商品图片</div>
        <div v-if="[10, 11].includes(form.classify)">
            <el-upload class="avatar-uploader" :data="uploadData" :headers="headers"
                :action="BASE_URL + '/biz/common/uploadImg'" :show-file-list="false" :on-success="uploadSuccess"
                :before-upload="uploadBefore">
                <img v-if="form.picture" :src="form.picture" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </div>
        <div v-if="[3, 10, 11].includes(form.classify)">
            <div class="row" v-if="form.classify==11">团购价格 <el-input class="ipt-num" v-model="tempForm.price" />元</div>
            <div class="row">活动参与总人次 <el-input class="ipt-num" v-model="form.headcount" placeholder="0为不限制" /></div>
            <div class="row">每日最多参与人次 <el-input class="ipt-num" v-model="form.headcountDay" placeholder="0为不限制" /></div>
            <div class="row">单人活动次数限制 <el-input class="ipt-num" v-model="form.personGetNum" placeholder="0为不限制" />个
            </div>
            <div class="row" v-if="form.classify == 3">注：用户参与过一次活动后，需用完全部券或券过期后才能再次参与同一商户同一种活动</div>
        </div>
        <div v-if="form.classify == 4">
            <div class="row">券包价格 <el-input class="ipt-num" v-model="tempForm.price" />元</div>
            <div class="row">券包总数量 <el-input class="ipt-num" v-model="form.countSum" placeholder="0为不限制" />个</div>
            <div class="row">每日券包数量 <el-input class="ipt-num" v-model="form.headcountDay" placeholder="0为不限制" />个</div>
            <div class="row">单人购买次数限制 <el-input class="ipt-num" v-model="form.countSolo" placeholder="0为不限制" />个</div>
        </div>
        <div v-if="form.classify == 8">
            <div class="row">提前多久发券 <el-input class="ipt-num" v-model="tempForm.dayBefor" placeholder="0为当天" />天</div>
        </div>
        <div v-if="form.classify == 9">
            <div class="row">消费满 <el-input class="ipt-num" v-model="tempForm.thresholdAmount" />元，可集一枚勋章</div>
            <div class="row">集满 <el-input class="ipt-num" v-model="form.numStar" />张，自动送券</div>
            <div class="row">每人参与次数 <el-input class="ipt-num" v-model="form.numPerson" placeholder="0为不限制" /></div>
            <!-- <div class="row">总活动次数 <el-input class="ipt-num" v-model="form.numTotal" /></div> -->
        </div>
        <!-- <div class="title" v-if="form.classify == 6">条件</div> -->
        <!-- <div v-if="form.classify == 6">
            <div class="row">消费满 <el-input class="ipt-num" v-model="tempForm.thresholdAmount" />元，可立减 <el-input class="ipt-num"
                    v-model="form.thresholdAmount" />元</div>
            <div class="row"><el-checkbox v-model="form.whetherRepeatSend" true-label="1"
                    false-label="0">可重复优惠</el-checkbox></div>

        </div> -->
        <div class="title" v-if="form.classify == 2">奖项设置</div>
        <div v-if="form.classify == 2" style="color: #999;font-size: 12px;margin-bottom: 10px;">
            每个奖项设置奖品数量后，该奖项奖品抽完，则不会有人再抽中该奖项，所有奖项中奖概率相加不得大于100%。</div>
        <div v-if="form.classify == 2">
            <div class="menu">
                <div :class="prizeIndex == 1 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(1)">一等奖</div>
                <div :class="prizeIndex == 2 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(2)">二等奖</div>
                <div :class="prizeIndex == 3 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(3)">三等奖</div>
                <div :class="prizeIndex == 4 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(4)">四等奖</div>
                <div :class="prizeIndex == 5 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(5)">五等奖</div>
                <div :class="prizeIndex == 6 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(6)">六等奖</div>
                <div :class="prizeIndex == 7 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(7)">七等奖</div>
                <div :class="prizeIndex == 8 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(8)">八等奖</div>
            </div>
            <div class="set-prize">
                <el-button style="margin-top: 20px;" type="warning" @click="addPrizeVisible = true">设置奖品</el-button>
                <div class="row" v-if="prizetemp.countCoupon != undefined">
                    <div v-for="(item, index) in list" :key="index">
                        <coupon-cus :item="item" v-if="item.id == prizetemp.couponId"></coupon-cus>
                    </div>
                    <div style="margin-left: 20px;">券数量：<el-input class="ipt-num" v-model="prizetemp.countCoupon" />
                    </div>
                    <div style="margin-left: 20px;">概率：<el-input class="ipt-num" v-model="prizetemp.chance" />%</div>
                    <div style="margin-left: 20px;">奖品数：<el-input class="ipt-num" v-model="prizetemp.countAward" />
                    </div>
                </div>
            </div>
        </div>
        <div class="title" v-if="form.classify == 2">抽奖条件</div>
        <div v-if="form.classify == 2">
            <div class="row">消费满 <el-input class="ipt-num" v-model="tempForm.thresholdAmount" />元，可抽奖</div>
            <div class="row">每次抽奖机会 <el-input class="ipt-num" v-model="form.drawNum" />次</div>
            <div class="row">每人最多可中奖次数 <el-input class="ipt-num" v-model="form.winNum" /></div>
        </div>
        <div class="row">活动说明 <el-input type="textarea" autosize class="ipt-num" style="width: 90%;"
                v-model="form.instruction" /></div>
        <div class="action">
            <el-button type="primary" @click="back">返回</el-button>
            <el-button type="success" @click="saveHandler">保存</el-button>
        </div>

        <el-dialog title="选择优惠券" :visible.sync="addCouponVisible" width="1300px">
            <el-radio-group v-model="couponId" class="coupon-cnt">
                <el-radio :label="item.id" v-for="(item, index) in list" :key="index">
                    <couponCus :item="item"></couponCus>
                </el-radio>
            </el-radio-group>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCouponVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmCoupon">确认</el-button>
            </span>
        </el-dialog>

        <el-dialog title="奖项设置" :visible.sync="addPrizeVisible">
            <div class="add-prize">
                <div class="row">
                    优惠券：<div v-for="(item, index) in list" :key="index">
                        <coupon-cus :item="item" v-if="item.id == prizetemp.couponId"></coupon-cus>
                    </div> <el-button type="success" @click="addCouponVisible = true"
                        style="margin-left: 30px;">选择优惠券</el-button>
                </div>
                <div class="row">
                    券数量： <el-input class="ipt-num" v-model="prizetemp.countCoupon" />
                </div>
                <div class="row">
                    中奖率： <el-input class="ipt-num" v-model="prizetemp.chance" />%
                </div>
                <div class="row">
                    奖品数： <el-input class="ipt-num" v-model="prizetemp.countAward" />
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addPrizeVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmPrize">确认</el-button>
            </span>
        </el-dialog>

        <el-dialog title="新增门槛" :visible.sync="addThresholdVisible">
            <div class="add-th">
                <el-input type="number" v-model="tempForm.thresholdAmount"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addThresholdVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAddTh">确认</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { BASE_URL, couponList, activityAdd, activityEdit, activityGetById } from "@/network/request";
import couponCus from '@/components/coupon-cus.vue';
export default {
    components: { couponCus },
    data() {
        return {
            BASE_URL: BASE_URL,
            addThresholdVisible: false,
            addCouponVisible: false,
            addPrizeVisible: false,
            title: "",
            id: "",
            timeArr: [],
            couponId: null,
            couponArr: [],
            tempForm: {
                thresholdAmount: null,
                price: null,
                prizeChance: null
            },
            form: {
                shopId: null,
                nickname: "",
                classify: "",
                timeStartStamp: "",
                timeEndStamp: "",
                thresholdAmount: null,
                buySendClassify: 0,
                useNotSend: null,
                decreaseAndSend: null,
                whetherRepeatSend: null,
                headcount: null,
                headcountDay: null,
                couponList: [],

                price: null,             //券包价格
                numStar: null,
                numPerson: null,
                numTotal: null,

                drawNum: null,
                winNum: null,
                prizeList: []
            },
            prizetemp: {
                rank: null,
                couponId: null,
                chance: null,
                countCoupon: null,
                countAward: null,
            },
            prizeIndex: 1,
            index: null,
            list: [],
            getArg: {
                page: 1,
                size: 50,
                content: {
                    shopId: null,
                    classify: null,
                }
            },
            total: 0,
            uploadData: {
                classify: null,
                shopId: null,
            },
            headers: {},
        }
    },
    methods: {
        uploadBefore(v) {
            if (v.size > 1020 * 1024 * 1024) {
                this.$message.warning("上传文件大小限制为1M")
                return false;
            }
            this.uploadData.classify = 1
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        uploadSuccess(v) {
            this.loading.close()
            this.form.picture = v.data
        },
        setCoupon(v) {
            this.index = v.$index
            this.addCouponVisible = true
        },
        buySendChange() {
            this.form.thresholdAmount = 0
            this.form.couponList = []
        },
        confirmAddTh() {
            this.addThresholdVisible = false
            let obj = {}
            obj.thresholdAmount = this.tempForm.thresholdAmount * 100
            obj.couponId = 0
            obj.count = 1
            this.form.couponList.push(obj)
        },
        prizeIndexChange(v) {
            this.prizetemp = {}
            this.prizeIndex = v
            for (let i = 0; i < this.form.prizeList.length; i++) {
                if (this.form.prizeList[i].rank == v) {
                    this.prizetemp = this.form.prizeList[i]
                    return;
                }
            }
        },
        // 取消优惠券
        cancelCoupon(v) {
            let id = v.couponId
            let index = [];
            this.form.couponList.map((obj, i) => {
                if (obj.couponId == id) {
                    index.push(i)
                }
            })
            for (let i = 0; i < index.length; i++) {
                this.form.couponList.splice(index[i], 1)
            }

        },
        confirmPrize() {
            this.prizetemp.rank = this.prizeIndex
            this.addPrizeVisible = false
            for (let i = 0; i < this.form.prizeList.length; i++) {
                if (this.form.prizeList.rank == this.prizetemp.rank) {
                    this.form.prizeList.splice(i, 1)
                    this.form.prizeList.push(this.prizetemp)
                    return;
                }
            }
            this.form.prizeList.push(this.prizetemp)
        },
        // 确认选择优惠券
        confirmCoupon() {
            if (this.form.classify == 1 && this.form.buySendClassify == 1) {
                console.log(this.form.couponList[this.index]);
                this.form.couponList[this.index].couponId = this.couponId
                this.addCouponVisible = false
                return;
            }
            if (this.form.classify != 2) {
                let obj = {}
                obj.couponId = this.couponId
                obj.count = 1
                this.form.couponList.push(obj)
            } else {
                this.prizetemp.couponId = this.couponId
            }
            this.addCouponVisible = false
        },
        // 获取所有优惠券
        getData() {
            couponList(this.getArg).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.list = data.data.data
                    this.total = data.data.total
                }
            })
        },
        getInfo() {
            activityGetById(this.id).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.form = data.data
                    this.timeArr.push(new Date(this.form.timeStartStamp))
                    this.timeArr.push(new Date(this.form.timeEndStamp))
                    this.tempForm.thresholdAmount = this.form.thresholdAmount / 100
                    this.tempForm.prizeChance = this.form.firstChance
                    this.tempForm.price = this.form.price / 100
                    if (this.form.prizeList != undefined && this.form.prizeList.length > 0) {
                        this.prizetemp = this.form.prizeList[0]
                    }
                    let type = this.form.classify
                    type == 1 ? this.title = "买单送券" : type == 2 ? this.title = "买单抽奖" : type == 3 ? this.title = "异业发券" : type == 4 ? this.title = "购买券包" : type == 5 ? this.title = "定向发券" : type == 6 ? this.title = "消费满减" : type == 7 ? this.title = "积分抽奖" : type == 8 ? this.title = "生日送券" : type == 9 ? this.title = "集卡福利" : this.title = type
                }
            })
        },
        // 保存活动
        saveHandler() {
            if (this.id != 0) {
                this.editHandler()
            } else {
                this.addHandler()
            }
        },
        addHandler() {
            this.form.price = this.tempForm.price * 100
            this.form.thresholdAmount = this.tempForm.thresholdAmount * 100
            activityAdd(this.form).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.$message.success(data.message);
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        editHandler() {
            this.form.price = this.tempForm.price * 100
            this.form.thresholdAmount = this.tempForm.thresholdAmount * 100
            activityEdit(this.form).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.$message.success(data.message);
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        timeChange() {
            //console.log(this.timeArr);
            this.form.timeStartStamp = new Date(this.timeArr[0]).getTime()
            this.form.timeEndStamp = new Date(this.timeArr[1]).getTime()
        },
        back() {
            this.$router.go(-1)
        }
    },
    created() {
        let type = this.$route.params.type
        this.id = this.$route.params.id
        this.form.classify = parseInt(type)
        this.headers.token = window.localStorage.getItem("token");
        let shopId = parseInt(window.localStorage.getItem("shopId"));
        this.uploadData.shopId = shopId

        type == 1 ? this.title = "买单送券" : type == 2 ? this.title = "买单抽奖" : type == 3 ? this.title = "异业发券" : type == 4 ? this.title = "购买券包" : type == 5 ? this.title = "定向发券" : type == 6 ? this.title = "消费满减" : type == 7 ? this.title = "积分抽奖" : type == 8 ? this.title = "生日送券" : type == 9 ? this.title = "集卡福利" : type == 10 ? this.title = "霸王餐" : type == 11 ? this.title = "团购" : this.title = type
        this.getArg.content.shopId = shopId;
        this.form.shopId = shopId
        this.getData()
        if (this.id != 0) {
            this.getInfo()
        }
    }
}
</script>

<style lang="less">
.set {
    background-color: #fff;
    padding: 20px;

    .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }

    .avatar {
        width: 80px;
        height: 80px;
        display: block;
    }

    .header {
        font-size: 30px;
        font-weight: bold;
    }

    .title {
        margin: 30px 0;
        padding-left: 10px;
        border-left: red solid 4px;
    }

    .ipt-num {
        width: 100px;
        margin: 0 10px;
    }

    .display-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .coupon-cnt {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .item {
            margin-left: 10px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: cent er;
            background: #d4237a;
            padding: 10px;
            width: 300px;
            height: 122px;
            box-sizing: border-box;
            border-radius: 10px;
            color: #fff;

            .left {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }

            .right {
                margin-left: 20px;

                .top {
                    font-size: 20px;
                    text-align: center;
                    border-bottom: 1px dashed #fff;
                    padding-bottom: 4px;
                }

                .price {
                    margin: 4px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;

                    .one {
                        font-size: 20px;
                        padding-bottom: 4px;
                    }

                    .two {
                        font-size: 50px;
                        margin: 0 10px;
                    }

                    .three {
                        height: 50px;

                        .four {
                            font-size: 18px;
                            margin-top: 4px;
                        }

                        .five {
                            margin-top: 10px;
                            font-size: 10px;
                        }
                    }
                }

                .time {
                    font-size: 10px;
                    border-bottom: 1px dashed #fff;
                    padding-bottom: 4px;
                }
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: row;

        .item {
            padding: 10px 20px;
            margin-right: 20px;
            color: #333;
            cursor: pointer;
        }

        .active {
            background-color: #409EFF;
            color: #fff;
        }
    }

    .set-prize {
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }



    .row {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .action {
        margin-top: 20px;
    }
}
</style>