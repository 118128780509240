<template>
    <div class="zfb-add">
        <el-form :rules="rules" ref="addZfbInfo" :model="addZfbInfo" label-width="80px">
            <el-form-item label="商家经营类目" prop="licenseNumber">
                <el-input v-model="addZfbInfo.mccCode" />
                <div class="tips"><a href="https://opendocs.alipay.com/common/02khjv" target="_blank">商家经营类目</a>
                </div>
            </el-form-item>
            <el-form-item label="营业执照照片">
                <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                    :show-file-list="false" :on-success="yyzzSuccess">
                    <img v-if="addZfbInfo.businessLicensePic" :src="addZfbInfo.businessLicensePic" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="营业执照号码" prop="licenseNumber">
                <el-input v-model="addZfbInfo.businessLicenseNo" />
            </el-form-item>
            <el-form-item label="企业特殊资质图片">
                <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                    :show-file-list="false" :on-success="tszzSuccess">
                    <img v-if="addZfbInfo.specialLicensePic" :src="addZfbInfo.specialLicensePic" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="是否长期">
                <el-radio-group v-model="addZfbInfo.longTerm">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="营业期限" prop="licenseNumber">
                <el-input v-model="addZfbInfo.date_limitation" placeholder="格式：0000-00-00" />
            </el-form-item>
        </el-form>
        <div>
            <el-button type="primary">返回</el-button>
            <el-button type="success" @click="save">保存</el-button>
        </div>
    </div>
</template>

<script>
import {
    BASE_URL,
    shopApplymentGetByShopId,
    shopApplymentAdd
} from "@/network/request";
export default {
    data() {
        return {
            shopId: null,
            BASE_URL: BASE_URL,
            addZfbInfo: {
                id: null,
                businessLicensePic: "",
                specialLicensePic: "",
            },
            dataForm: {
                shopId: 0,
                classify: 2
            },
            rules: {},
        }
    },
    methods: {
        save() {
            shopApplymentAdd(this.addZfbInfo)
        },
        yyzzSuccess(v) {
            console.log(v);
            if (v.flag) {
                this.addZfbInfo.businessLicensePic = v.data.fileName
            }
        },
        tszzSuccess(v) {

            if (v.flag) {
                this.addZfbInfo.specialLicensePic = v.data.fileName
            }

        },
        getData() {
            shopApplymentGetByShopId(this.shopId).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.addZfbInfo.id = data.data.id
                }
            })
        }
    },
    created() {
        this.shopId = parseInt(window.localStorage.getItem("shopId"))
        this.dataForm.shopId = this.shopId
        this.getData()
    }
}
</script>

<style lang="less">
.zfb-add {
    background-color: #fff;
    padding: 20px;
    line-height: 1;

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 80px;
        height: 80px;
        line-height: 80px;
        text-align: center;
    }

    .avatar {
        width: 80px;
        height: 80px;
        display: block;
    }

    .tips {
        text-align: left;

        a {
            color: #ff0000;
        }
    }
}
</style>