<template>
    <div class="coupon-add">
        <div class="title">{{ title }}</div>
        <div class="content">
            <el-form ref="form" :model="form" label-width="90px">
                <el-form-item label="券名称">
                    <el-input v-model="form.nickname"></el-input>
                </el-form-item>
                <el-form-item label="券样式">
                    <el-radio-group v-model="form.bgUrl" class="bg-cnt">
                        <el-radio label="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg00.png">
                            <img src="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg00.png" alt="">
                        </el-radio>
                        <el-radio label="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg10.png">
                            <img src="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg10.png" alt="">
                        </el-radio>
                        <el-radio label="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg20.png">
                            <img src="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg20.png" alt="">
                        </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="券有效期">
                    领券第<el-input v-model="form.validStart" class="ipt-num" placeholder="0为立即生效" />天后生效，有效期<el-input
                        v-model="form.validDay" class="ipt-num" />天
                </el-form-item>
                <el-form-item label="优惠券金额">
                    <el-input v-model="tempForm.discount"></el-input>
                </el-form-item>
                <el-form-item label="券有星期">
                    <el-radio-group v-model="weekType" @change="typeChange" class="bg-cnt">
                        <el-radio label="1">全部星期</el-radio>
                        <el-radio label="0">部分星期</el-radio>
                    </el-radio-group>
                    <div>
                        <el-checkbox-group v-model="weekList" @change="weekChange">
                            <el-checkbox label="1">星期一</el-checkbox>
                            <el-checkbox label="2">星期二</el-checkbox>
                            <el-checkbox label="3">星期三</el-checkbox>
                            <el-checkbox label="4">星期四</el-checkbox>
                            <el-checkbox label="5">星期五</el-checkbox>
                            <el-checkbox label="6">星期六</el-checkbox>
                            <el-checkbox label="0">星期日</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item label="消费门槛">
                    <el-input v-model="tempForm.threshold" placeholder="0元为不限制"></el-input>
                </el-form-item>

                <el-form-item label="每满">
                    <el-input v-model="tempForm.thresholdEach" class="ipt-num" style="margin-left: 0;width: 240px;" placeholder="此金额必须小于等于消费门槛"></el-input>用一张
                </el-form-item>
                <!-- 
                <el-form-item label="券数量">
                    <el-input v-model="form.name" class="ipt-num"></el-input>
                </el-form-item> -->

                <el-form-item label="使用限制">
                    本券一次最多使用<el-input v-model="form.thresholdNum" class="ipt-num" placeholder="0为不限制"></el-input>张
                </el-form-item>
                <el-form-item label="核销码">
                    <el-input autosize v-model="form.captcha"></el-input>
                </el-form-item>
                <el-form-item label="使用说明">
                    <el-input type="textarea" autosize v-model="form.instruction"></el-input>
                </el-form-item>
                <el-form-item label="是否互斥券">
                    <el-radio-group v-model="form.isMutex" class="bg-cnt">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <span style="margin-left: 20px;color: #999;">(是：表示不可与其他券一起使用；否：表示可与其他券一起使用。)</span>
                </el-form-item>
            </el-form>
        </div>
        <el-button type="primary" @click="back">返回</el-button>
        <el-button type="success" @click="saveHandler">保存</el-button>
    </div>
</template>

<script>
import { couponAdd, couponGetById } from "@/network/request";
export default {
    data() {
        return {
            title: "新增优惠券",
            weekType: "1",
            weekList: ["0", "1", "2", "3", "4", "5", "6"],
            tempForm: {
                discount: null,
                threshold: null,
                thresholdEach: null,
            },
            form: {
                shopId: null,
                nickname: "",
                bgUrl: "",
                validStart: null,
                validDay: null,
                discount: null,
                validWeek: "",
                threshold: null,
                thresholdEach: null,
                thresholdNum: null,
                isMutex: null,
                instruction: "",
            }
        }
    },
    methods: {
        // 券有星期change
        typeChange(v) {
            if (v == 1) {
                this.weekList = ["0", "1", "2", "3", "4", "5", "6"]
            } else {
                this.weekList = []
            }
        },
        // 星期change
        weekChange() {
            if (this.weekList.length != 7) {
                this.weekType = "0"
            } else {
                this.weekType = "1"
            }
        },
        // 保存
        saveHandler() {
            if(this.form.validDay<=null){
                this.$message.warning("有效期必须大于0天")
                return;
            }

            if(this.tempForm.threshold<this.tempForm.thresholdEach){
                this.$message.warning("每满金额必须小于等于消费门槛！")
                return;
            }
            this.form.validWeek = this.weekList.join("_")
            this.form.discount = this.tempForm.discount * 100
            this.form.threshold = this.tempForm.threshold * 100
            this.form.thresholdEach = this.tempForm.thresholdEach * 100
            couponAdd(this.form).then((res) => {
                let data = res.data;
                if (data.code==200) {
                    this.$message.success("操作成功！");
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        back() {
            this.$router.go(-1)
        },
        getData(v) {
            couponGetById(v).then((res) => {
                let data = res.data;
                if (data.code==200) {
                    this.form = data.data
                    this.tempForm.discount = this.form.discount / 100
                    this.tempForm.threshold = this.form.threshold / 100
                    this.tempForm.thresholdEach = this.form.thresholdEach / 100
                    this.weekList = this.form.validWeek.split("_")
                    if (this.weekList.length != 7) {
                        this.weekType = "0"
                    } else {
                        this.weekType = "1"
                    }
                } else {
                    this.$message.error(data.message);
                }
            });
        }

    },
    created() {
        this.form.bgUrl="https://payment-marketing.oss-cn-chengdu.aliyuncs.com/app/coupon_bg00.png"
        this.form.shopId = window.localStorage.getItem("shopId");
        let type = this.$route.params.type
        if (type != 0) {
            this.title = "修改优惠券"
            this.getData(type)
        }
    }
}
</script>

<style lang="less">
.coupon-add {
    background-color: #fff;
    padding: 20px;

    .title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .ipt-num {
        width: 120px;
        margin: 0 10px;
    }

    .bg-cnt {
        img {
            width: 300px;
            height: 100px;
        }
    }
}
</style>
