<template>
    <div class="activity-details">
        <div class="header">{{ title }}</div>
        <div class="title">活动名称</div>
        <div>
            <el-input disabled style="width: 400px;" v-model="info.nickname" placeholder="请输入活动名称" />
        </div>
        <div class="title">活动时间</div>
        <div>
            <el-date-picker disabled type="daterange" v-model="timeArr" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
        </div>
        <div class="title" v-if="[3, 4].includes(info.classify)">展示设置</div>
        <div class="display" v-if="[3, 4].includes(info.classify)">
            <div class="display-row">
                <div style="margin-right: 20px;">支付页面</div>
                <el-radio-group v-model="info.showPay" style="display: flex;">
                    <el-radio disabled :label="1">展示</el-radio>
                    <el-radio disabled :label="0">不展示</el-radio>
                </el-radio-group>
            </div>
            <div class="display-row" style="margin-top: 10px;">
                <div style="margin-right: 20px;">其它页面</div>
                <el-radio-group v-model="info.showOther" style="display: flex;">
                    <el-radio disabled :label="1">展示</el-radio>
                    <el-radio disabled :label="0">不展示</el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="title" v-if="[1, 3, 4, 8, 9, 10, 11].includes(info.classify) || info.buySendClassify == 0">优惠券</div>
        <div v-if="[1, 3, 4, 8, 9, 10, 11].includes(info.classify) || info.buySendClassify == 0">
            <!-- <div>
                <el-button type="success" @click="addCouponVisible = true">选择优惠券</el-button>
                <el-button type="warning">新增优惠券</el-button>
            </div> -->
            <div class="coupon-table" style="margin-top: 20px;">
                <el-table :data="info.couponList" border style="width: 100%">
                    <el-table-column label="优惠券" align="center">
                        <template slot-scope="v">
                            <div class="coupon-cnt">
                                <div v-for="(item, index) in couponList" :key="index">
                                    <coupon-cus :item="item" v-if="v.row.couponId == item.id"></coupon-cus>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量" align="center">
                        <template slot-scope="v">
                            <el-input-number disabled v-model="v.row.count"></el-input-number>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作" align="center">
                        <template slot-scope="v">
                            <el-button type="primary" plain @click="cancelCoupon(v.row)">取消</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
        <div class="title" v-if="[10, 11].includes(info.classify)">商品图片</div>
        <div v-if="[10, 11].includes(info.classify)">
            <el-image style="width: 100px; height: 100px" :src="info.picture" fit="contain"
                :preview-src-list="[info.picture]"></el-image>
        </div>
        <div class="title" v-if="info.classify == 1 && info.buySendClassify == 1">设置梯度</div>
        <div v-if="info.classify == 1 && info.buySendClassify == 1">
            <!-- <div><el-button type="success" @click="addThresholdVisible = true">新增门槛</el-button></div> -->
            <div class="coupon-table" style="margin-top: 20px;">
                <el-table :data="info.couponList" border style="width: 100%">
                    <el-table-column prop="thresholdAmount" label="消费门槛(元)" align="center">
                        <template slot-scope="v">
                            <p>{{ (v.row.thresholdAmount / 100).toFixed(2) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="优惠券" align="center">
                        <template slot-scope="v">
                            <div class="coupon-cnt">
                                <!-- <p>{{ v.row.couponId }}</p> -->
                                <div v-for="(item) in couponList" :key="item.id">
                                    <coupon-cus :item="item" v-if="v.row.couponId == item.id"></coupon-cus>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量(张)" align="center">
                        <template slot-scope="v">
                            <el-input-number disabled v-model="v.row.count"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="v">
                            <el-button type="danger" plain disabled>删除</el-button>
                            <el-button type="primary" plain disabled @click="setCoupon(v)">设置优惠券</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

        </div>
        <div class="title" v-if="[1, 3, 4, 9].includes(info.classify)">发券设置</div>
        <div v-if="info.classify == 1">
            <div class="row" v-if="info.classify == 1 && info.buySendClassify == 0">当消费满 <el-input disabled
                    class="ipt-num" :value="info.thresholdAmount / 100" />元时赠送</div>
            <div class="row">
                <el-checkbox disabled v-model="info.useNotSend" :true-label="1" :false-label="0">用券不送券</el-checkbox>
                <el-checkbox disabled v-model="info.decreaseAndSend" :true-label="1"
                    :false-label="0">减券金额后赠送券</el-checkbox>
                <el-checkbox disabled v-model="info.whetherRepeatSend" :true-label="1" :false-label="0"
                    v-if="info.classify == 1 && info.buySendClassify == 0">可重复赠送</el-checkbox>
            </div>
        </div>
        <div v-if="info.classify == 2">
            <div class="title">奖项</div>
            <div class="row">
                <div class="menu">
                    <div :class="prizeIndex == 1 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(1)">一等奖</div>
                    <div :class="prizeIndex == 2 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(2)">二等奖</div>
                    <div :class="prizeIndex == 3 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(3)">三等奖</div>
                    <div :class="prizeIndex == 4 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(4)">四等奖</div>
                    <div :class="prizeIndex == 5 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(5)">五等奖</div>
                    <div :class="prizeIndex == 6 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(6)">六等奖</div>
                    <div :class="prizeIndex == 7 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(7)">七等奖</div>
                    <div :class="prizeIndex == 8 ? ['item', 'active'] : ['item']" @click="prizeIndexChange(8)">八等奖</div>
                </div>
                <div class="set-prize">
                    <div class="row" v-if="prizetemp.countCoupon != undefined">
                        <div v-for="(item, index) in this.couponList" :key="index">
                            <coupon-cus :item="item" v-if="item.id == prizetemp.couponId"></coupon-cus>
                        </div>
                        <div style="margin-left: 100px;">券数量：
                            <el-input class="ipt-num" v-model="prizetemp.countCoupon" disabled
                                style="margin-left: 30px;" />
                        </div>
                        <div style="margin-left: 100px;">概率：<el-input class="ipt-num" disabled
                                v-model="prizetemp.chance" />%
                        </div>
                        <div style="margin-left: 100px;">奖品数：<el-input class="ipt-num" disabled
                                v-model="prizetemp.countAward" />
                        </div>
                    </div>
                </div>
                <div class="title">抽奖条件</div>
                <div class="row">当消费满 <el-input disabled class="ipt-num" :value="info.thresholdAmount / 100" />元时，可抽奖
                </div>
                <div class="row">每次抽奖机会 <el-input disabled class="ipt-num" v-model="info.drawNum" /></div>
                <div class="row">每人最多可中奖次数 <el-input disabled class="ipt-num" v-model="info.winNum" /></div>
            </div>
        </div>
        <div v-if="[3, 10, 11].includes(info.classify)">
            <div class="row"  v-if="info.classify==11">券包价格 <el-input disabled class="ipt-num" :value="info.price / 100" />元</div>
            <div class="row">活动参与总人次 <el-input disabled class="ipt-num" v-model="info.headcount" />（0为不限制）</div>
            <div class="row">每日最多参与人次 <el-input disabled class="ipt-num" v-model="info.headcountDay" />（0为不限制）</div>
            <div class="row">单人活动次数限制 <el-input class="ipt-num" disabled v-model="info.personGetNum" />（0为不限制）</div>
            <div class="row">注：用户参与过一次活动后，需用完全部券或券过期后才能再次参与同一商户同一种活动</div>
        </div>
        <div v-if="info.classify == 4">
            <div class="row">券包价格 <el-input disabled class="ipt-num" :value="info.price / 100" />元</div>
            <div class="row">单人购买次数限制 <el-input class="ipt-num" disabled v-model="info.countSolo" />（0为不限制）</div>
            <div class="row">券包总数量 <el-input class="ipt-num" disabled v-model="info.countSum" />（0为不限制）</div>
            <div class="row">每日券包数量 <el-input class="ipt-num" disabled v-model="info.headcountDay"
                    placeholder="0为不限制" />个（0为不限制）</div>
        </div>
        <div v-if="info.classify == 8">
            <div class="row">提前送券天数 <el-input class="ipt-num" disabled v-model="info.dayBefore" />天（0为不提前）</div>
        </div>
        <div v-if="info.classify == 9">
            <div class="row">消费满 <el-input disabled class="ipt-num" :value="info.thresholdAmount / 100" />元，可集一枚勋章</div>
            <div class="row">集满 <el-input disabled class="ipt-num" v-model="info.numStar" />张，自动送券</div>
            <div class="row">每人参与次数 <el-input disabled class="ipt-num" v-model="info.numPerson" />（0为不限制）</div>
            <!-- <div class="row">总活动次数 <el-input disabled class="ipt-num" v-model="info.numTotal" /></div> -->
        </div>
        <div class="title" v-if="info.classify == 6">条件</div>
        <div v-if="info.classify == 6">
            <div class="row">消费满 <el-input disabled class="ipt-num" :value="info.thresholdAmount / 100" />元，可立减
                <el-input class="ipt-num" v-model="info.thresholdAmount" />元
            </div>
            <div class="row"><el-checkbox disabled v-model="info.whetherRepeatSend" true-label="1"
                    false-label="0">可重复优惠</el-checkbox></div>

        </div>
        <div class="title">活动说明</div>
        <div class="row" style="width: 90%;white-space: pre-wrap;">{{ info.instruction }}</div>
    </div>
</template>

<script>
import { couponList, activityGetById } from "@/network/request";
import couponCus from '@/components/coupon-cus.vue';
export default {
    components: { couponCus },
    data() {
        return {
            id: null,
            title: "",
            info: {},
            prizetemp: {},
            couponList: [],
            timeArr: [],
            prizeIndex: 1,
            tempForm: {
                thresholdAmount: null,
                price: null,
                prizeChance: null
            },
            getArg: {
                page: 1,
                size: 100,
                content: {
                    shopId: null,
                    classify: null,
                }
            },
        }
    },
    methods: {
        prizeIndexChange(v) {
            this.prizetemp = {}
            this.prizeIndex = v
            for (let i = 0; i < this.info.prizeList.length; i++) {
                if (this.info.prizeList[i].rank == v) {
                    this.prizetemp = this.info.prizeList[i]
                    return;
                }
            }
        },
        getData() {
            activityGetById(this.id).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.info = data.data
                    this.tempForm.prizeChance = this.info.firstChance
                    this.timeArr.push(new Date(this.info.timeStartStamp))
                    this.timeArr.push(new Date(this.info.timeEndStamp))
                    if (this.info.prizeList != undefined && this.info.prizeList.length > 0) {
                        this.prizetemp = this.info.prizeList[0]
                    }
                    let type = this.info.classify
                    type == 1 ? this.title = "买单送券" : type == 2 ? this.title = "买单抽奖" : type == 3 ? this.title = "异业发券" : type == 4 ? this.title = "购买券包" : type == 5 ? this.title = "定向发券" : type == 6 ? this.title = "消费满减" : type == 7 ? this.title = "积分抽奖" : type == 8 ? this.title = "生日送券" : type == 9 ? this.title = "集卡福利" : type == 10 ? this.title = "霸王餐" : type == 11 ? this.title = "团购" : this.title = type
                }
            })
        },
        // 获取所有优惠券
        getAllCoupon() {
            couponList(this.getArg).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.couponList = data.data.data
                }
            })
        },
    },
    created() {
        this.getArg.content.shopId = parseInt(window.localStorage.getItem("shopId"));
        this.id = this.$route.params.id
        this.getData()
        this.getAllCoupon()
    }
}
</script>

<style lang="less" scoped>
.activity-details {
    background-color: #fff;
    padding: 20px;

    .el-radio {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .header {
        font-size: 30px;
        font-weight: bold;
    }

    .title {
        margin: 30px 0;
        padding-left: 10px;
        border-left: red solid 4px;
    }

    .ipt-num {
        width: 80px;
        margin: 0 10px;
    }

    .display-row {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .menu {
        display: flex;
        flex-direction: row;

        .item {
            padding: 10px 20px;
            margin-right: 20px;
            color: #333;
            cursor: pointer;
        }

        .active {
            background-color: #409EFF;
            color: #fff;
        }
    }

    .set-prize {
        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .row {
        margin-top: 20px;
    }

    .action {
        margin-top: 20px;
    }
}
</style>