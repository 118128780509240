<template>
  <div class="role">
    <div class="title">
      <el-button type="success" size="small" @click="visible = true">添加</el-button>
    </div>
    <el-table border v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
      :data="tableData" style="width: 100%" size="small">
      <el-table-column type="expand">
        <template slot-scope="v">
          <el-tag class="perm-item" v-for="(item, index) in v.row.permissions" :key="index">{{ getPermissionStr(item)
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="rid" label="RID" align="center"> </el-table-column>
      <el-table-column prop="nickname" label="角色名称" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="v">
          <el-button @click="edit(v.row)" type="warning" plain size="small">编辑</el-button>
          <el-button @click="del(v.row)" type="danger" plain size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="custom-page">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="getDataArg.page"
        :page-sizes="[10, 20, 40, 100]" :page-size="getDataArg.size" layout="total, sizes, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog :title="title" :visible="visible" :show-close="false" width="500px" :before-close="addBeforClose">
      <div class="content">
        <div class="item">
          <span>名称：</span><el-input v-model="addData.nickname" placeholder="请输入内容"></el-input>
        </div>
        <div class="item">
          <span>权限：</span>
          <el-cascader v-model="selected" :options="permissionTreeData" :props="props" collapse-tags clearable
            placeholder="请选择权限" style="width: 100%" :popper-class="'pop-cus'" @change="changed(1)"></el-cascader>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addBeforClose">取 消</el-button>
        <el-button type="primary" @click="handlerConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import {
  systemRoletList,
  systemPermissionsListAll,
  systemRoleAddOrSave,
  systemRoleDel,
} from "@/network/request";
export default {
  data() {
    return {
      title: "添加系统角色",
      visible: false,
      loading: true,
      getDataArg: {
        page: 1,
        size: 10,
        content: "",
      },
      total: 0,
      tableData: [],
      permissionData: [],
      permissionTreeData: [],
      addData: {
        nickname: "",
        permissions: [],
        permissionStr: ""
      },
      props: { multiple: true },
      selected: [],
    };
  },
  created() {
    this.getPermission();
    this.getData();
  },

  methods: {
    handlerConfirm() {
      this.loading = true;
      this.addData.companyId = parseInt(window.localStorage.getItem("shopId"));
      this.addData.classify = 3
      systemRoleAddOrSave(this.addData).then((res) => {
        this.visible = false
        let data = res.data;
        if (data.code==200 == true) {
          this.$message.success(data.message);
          this.getData();
          this.addData = {};
          this.selected = [];
        } else {
          this.$message.error(data.message);
        }
      });
      this.addVisible = false;
    },
    addBeforClose() {
      this.addData = {}
      this.selected = []
      this.visible = false
    },
    open() {
      return this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    },
    del(v) {
      this.open()
        .then(() => {
          systemRoleDel(v.rid).then((res) => {
            this.$message({
              type: "success",
              message: res.data.message,
            });
            this.getData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    edit(v) {
      this.title = "修改系统角色"
      this.selected = []
      this.visible = true;
      this.addData.rid = v.rid;
      this.addData.nickname = v.nickname;
      this.addData.permissions = v.permissions;
      this.addData.permissionStr = v.permissionStr;
      if (v.permissionStr != "") {
        this.selected = JSON.parse(v.permissionStr)
      }
    },
    arrFormat(arr) {
      let arr1 = arr.flat(Infinity);
      let arr2 = [...new Set(arr1)];
      return arr2;
    },

    changed(v) {
      let newArr = this.arrFormat(this.selected);
      if (v == 2) {
        this.editData.permissions = newArr;
        this.editData.permissionStr = JSON.stringify(this.selected);
      } else {
        this.addData.permissions = newArr;
        this.addData.permissionStr = JSON.stringify(this.selected);
      }
    },
    getPermissionStr(v) {
      for (let i = 0; i < this.permissionData.length; i++) {
        if (this.permissionData[i].pid == v) {
          return this.permissionData[i].nickname;
        }
      }
    },
    getData() {
      systemRoletList(this.getDataArg).then((res) => {
        this.loading = false;
        let data = res.data;
        if (data.code==200) {
          this.tableData = data.data.data;
          this.total = data.data.total;
        } else {
          this.$message.error(data.message);
        }
      });
    },
    getPermission() {
      systemPermissionsListAll().then((res) => {
        this.permissionData = res.data.data.data;
        let str = JSON.stringify(res.data.data.beautifyData);
        let s1 = str.replace(/pid/g, "value");
        let s2 = s1.replace(/nickname/g, "label");
        let s3 = s2.replace(/"children":\[]/g, '"n":1');
        this.permissionTreeData = JSON.parse(s3);
        // console.log(this.permissionTreeData);
      });
    },
    handleSizeChange(v) {
      this.loading = true;
      this.getDataArg.size = v;
      this.getData();
      // console.log(v);
    },
    handleCurrentChange(v) {
      this.loading = true;
      this.getDataArg.page = v;
      this.getData();
      // console.log(v);
    },
  },
};
</script>
  
<style lang='less'>
.pop-cus {
  max-height: 900px;
  overflow: auto;
}

.role {
  background-color: #fff;
  padding: 20px;

  .title {
    margin-bottom: 20px;
  }

  .custom-page {
    margin: 20px;
    text-align: center;
  }

  .perm-item {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .content {
    .item {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;

      span {
        width: 50px;
      }
    }
  }
}
</style>