<template>
  <div class="coupon-cus" :style="{ 'background': 'url(' + item.bgUrl + ') no-repeat','background-size': '100% 100%' }">
    <div class="left">
      <div class="top">{{ item.nickname }}</div>
      <div class="middle">
        <div class="one">￥</div>
        <div class="two">{{ (item.discount/100).toFixed(2) }}</div>
        <!-- <div class="three">50x3张</div> -->
      </div>
      <!-- <div class="bottom">领劵后第{{ item.validStart }}天生效，有效期{{ item.validDay }}天</div> -->
      <div class="bottom">使用门槛：{{ (item.thresholdEach/100).toFixed(2) }}</div>
    </div>
    <div class="right">
      <div class="action">
        <div class="lj">立即</div>
        <div>领取</div>
      </div>
      <div class="details">点击查看详情</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  }
}
</script>

<style lang="less" scoped>
.coupon-cus {
  font-size: 14px;
  line-height: 1;
  width: 300px;
  height: 100px;
  background-size: 100% 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  .left {
    // padding-left: 10px;
    width: 200px;
    height: 100px;
    // border: 1px solid black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .top {
      color: #AF865F;
      font-size: 13px;
      margin-bottom: 3px;
      padding-left: 20px;
    }

    .middle {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: center;

      .one {
        font-size: 16px;
        color: #E62129;
        font-weight: bold;
        padding-bottom: 5px;
      }

      .two {
        font-size: 50px;
        color: #E62129;
        font-weight: bold;
      }

      .three {
        margin-left: 5px;
        font-size: 19px;
        color: #AF865F;
        padding-bottom: 5px;
      }
    }

    .bottom {
      margin-top: 3px;
      font-size: 9px;
      color: #AF865F;
      padding-left: 20px;
    }
  }

  .right {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .action {
      color: #333;
      margin-top: 20px;
      background: #fae2ca;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      font-weight: bold;
      font-size: 13px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .lj {
        margin-bottom: 5px;
      }
    }

    .details {
      margin-top: 5px;
      background: #fae2ca;
      font-size: 9px;
      color: #333;
      width: 90px;
      height: 18px;
      border-radius: 6px;
      text-align: center;
      line-height: 18px;
      font-size: 10px;
    }
  }
}
</style>