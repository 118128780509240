<template>
    <div class="partner">
        <div class="header">
            <el-button type="success" size="small" @click="add">添加</el-button>
        </div>
        <el-table :data="tableData" border style="width: 100%">

            <el-table-column prop="shopId" label="shopID" align="center">
            </el-table-column>
            <el-table-column prop="shopName" label="商户名称" align="center">
            </el-table-column>
            <el-table-column fixed="right" prop="action" label="操作" width="280px" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" plain size="small" @click="del(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="custom-page">
            <el-pagination layout="total,prev, pager, next" :total="total" @current-change="pageChange">
            </el-pagination>
        </div>
        <el-dialog title="新增" :visible.sync="visible" width="1000px">
            <div class="add-content">
                <div class="search">shopID：<el-input v-model="searchShopId"
                        style="width: 200px;margin-right: 10px;"></el-input> <el-button type="success" size="small"
                        @click="search">搜索</el-button> </div>
                <div class="tab">
                    <el-table :data="list" border style="width: 100%">
                        <el-table-column prop="shopId" label="shopID" align="center">
                        </el-table-column>
                        <el-table-column prop="shopName" label="商户名称" align="center">
                        </el-table-column>
                        <el-table-column fixed="right" prop="action" label="操作" width="280px" align="center">
                            <template slot-scope="scope">
                                <el-button type="primary" plain size="small" @click="confirm(scope.row)">确认</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="confirmHandler">确 定</el-button>
            </span> -->
        </el-dialog>
    </div>
</template>

<script>
import { shopPartnerAdd, shopPartnerDel, shopPartnerSearch, shopPartnerList } from "@/network/request";
export default {
    data() {
        return {
            visible: false,
            addForm: {
                id: null,
            },
            getArg: {
                page: 1,
                size: 10,
                content: ""
            },
            total: 0,
            tableData: [],
            list: [],
            shopId: null,
            searchShopId: null
        }
    },
    methods: {
        add() {
            this.visible = true
        },
        del(v) {
            this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                shopPartnerDel(v.id).then((res) => {
                    let data = res.data
                    if (data.code == 200) {
                        this.$message.success("删除成功！")
                        this.getData();
                    } else {
                        this.$message.error(data.message)
                    }
                });
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            });
        },
        confirm(v) {
            let obj = {}
            obj.shopId = this.shopId
            obj.shopPartnerId = v.shopId
            shopPartnerAdd(obj).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.$message.success("添加成功！");
                    this.getData()
                } else {
                    this.$message.error(data.message);
                }
            });
            this.visible = false
        },
        pageChange() { },
        search() {
            this.list = []
            shopPartnerSearch(this.searchShopId).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.list.push(data.data);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getData() {
            this.getArg.content = this.shopId
            shopPartnerList(this.getArg).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                } else {
                    this.$message.error(data.message);
                }
            });
        }
    },
    created() {
        this.shopId = window.localStorage.getItem("shopId")
        this.getData()
    }
}
</script>

<style lang="less">
.partner {
    background-color: #fff;
    padding: 20px;

    .header {
        margin-bottom: 20px;
    }

    .custom-page {
        margin-top: 20px;
        text-align: center;

    }

    .add-content {
        .search {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
        }
    }
}
</style>