<template>
    <div class="activity-list">
        <div class="search">
            <span>营销类型</span>
            <el-select v-model="getArg.content.classify" clearable placeholder="请选择营销活动类型" style="margin: 0 20px;">
                <el-option label="买单送券" :value="1"></el-option>
                <el-option label="买单抽奖" value="2"></el-option>
                <el-option label="异业发券" value="3"></el-option>
                <el-option label="购买券包" value="4"></el-option>
                <el-option label="定向发券" value="5"></el-option>
                <el-option label="消费满减" value="6"></el-option>
                <el-option label="积分抽奖" value="7"></el-option>
                <el-option label="生日送券" value="8"></el-option>
                <el-option label="集卡福利" value="9"></el-option>
                <el-option label="霸王餐" value="10"></el-option>
            </el-select>
            <!-- <span style="margin-right: 20px;">活动时间</span>
            <el-date-picker type="daterange" v-model="timeArr" range-separator="至" start-placeholder="开始日期"
                @change="timeChange" end-placeholder="结束日期">
            </el-date-picker> -->
            <el-button type="primary" @click="getData" style="margin-left: 20px;">搜索</el-button>
        </div>
        <div class="content">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="classify" label="活动类型" align="center">
                    <template slot-scope="v">
                        <p>{{ v.row.classify == 1 ? "买单送券" : v.row.classify == 2 ? "买单抽奖" : v.row.classify == 3 ? "异业发券" :
                            v.row.classify == 4 ? "购买券包"
                                : v.row.classify == 5 ? "定向发券" : v.row.classify == 6 ? "消费满减" : v.row.classify == 7 ?
                                    "积分抽奖" : v.row.classify == 8 ? "生日送券" : v.row.classify == 9 ? "集卡福利": v.row.classify == 10 ? "霸王餐" :v.row.classify == 11 ? "团购" : v.row.classify }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="nickname" label="活动名称" align="center">
                </el-table-column>
                <el-table-column label="活动时间" align="center" width="180px">
                    <template slot-scope="v">
                        <p>{{ v.row.timeStart | timestamp2str | timeStrSubstring }}至{{ v.row.timeEnd | timestamp2str |
                            timeStrSubstring }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="userNickname" label="创建人" align="center">
                </el-table-column>

                <el-table-column label="状态" align="center">
                    <template slot-scope="v">
                        <p :style="{ 'color': v.row.status == 1 ? '#67C23A' : '#F56C6C' }">{{ v.row.status == 1 ? "进行中" : "已关闭" }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" align="center" width="160px">
                    <template slot-scope="v">
                        {{ v.row.timeCreate | timestamp2str }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="400px">
                    <template slot-scope="v">
                        <el-button type="primary" plain @click="gotoDetails(v.row)">详情</el-button>
                        <el-button type="warning" plain @click="edit(v.row)">修改</el-button>
                        <!-- <el-button type="success" plain>营销数据</el-button> -->
                        <el-popconfirm style="margin-left: 10px;" :title="v.row.status == 1 ? '是否确认关闭' : '是否确认打开'"
                            @confirm="confirmStatus(v.row)">
                            <el-button slot="reference" :type="v.row.status == 1 ? 'danger' : 'success'" plain>{{
                                v.row.status == 1 ? "关闭" : "打开"
                            }}</el-button>
                        </el-popconfirm>

                        <el-popconfirm style="margin-left: 10px;" title="是否确认删除" @confirm="confirmDel(v.row)">想·
                            <el-button slot="reference" type="danger" plain>删除</el-button>
                        </el-popconfirm>

                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { activityChangeStatus, activityList, activityDel } from "@/network/request";
export default {
    data() {
        return {
            timeArr: [],
            tableData: [],
            getArg: {
                page: 1,
                size: 10,
                content: {
                    shopId: null,
                    classify: null,
                }
            },
            total: 0,
        }
    },
    methods: {
        confirmDel(row) {
            activityDel(row.id).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.$message.success("删除成功！")
                } else {
                    this.$message.error(data.message)
                }
                this.getData()
            })
        },
        pageChange(v) {
            this.getArg.page = v
            this.getData()
            console.log(v);
        },
        confirmStatus(row) {
            let status = row.status == 0 ? 1 : 0
            activityChangeStatus(row.id, status).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.$message.success("操作成功！")
                } else {
                    this.$message.error(data.message)
                }
                this.getData()
            })
        },
        gotoDetails(row) {
            this.$router.push("/activityDetails/" + row.id)
        },
        edit(row) {
            this.$router.push("/activitySet/" + row.classify + "/" + row.id)
        },
        getData() {
            activityList(this.getArg).then(res => {
                let data = res.data
                if (data.code == 200) {
                    this.tableData = data.data.data
                    this.total = data.data.total
                }
            })
        },
        timeChange() {
            this.getArg.content.startTimestamp = new Date(this.timeArr[0]).getTime()
            this.getArg.content.endTimestamp = new Date(this.timeArr[1]).getTime()
        },
    },
    created() {
        this.getArg.content.shopId = parseInt(window.localStorage.getItem("shopId"));
        this.getData()
    }
}
</script>

<style lang="less" scoped>
.activity-list {
    background-color: #fff;
    padding: 20px;

    .search {
        margin-bottom: 20px;

        .ipt {
            width: 80px;
        }
    }

    .page {
        margin-top: 20px;
        text-align: center;
    }
}
</style>