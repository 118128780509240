<template>
    <div class="statement">
        <el-tabs type="border-card" @tab-click="tabChange">
            <el-tab-pane label="收款明细">
                <div class="cnt">
                    <div class="item">
                        <div class="title">商家账单金额</div>
                        <div class="money">收款笔数：<span class="text-len">{{ accountInfo.one1Count }}</span> 笔</div>
                        <div class="money">收款金额：<span class="text-len">{{ (accountInfo.one1Sum / 100).toFixed(2) }}</span> 元
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">优惠账单金额</div>
                        <div class="money">优惠笔数：<span class="text-len">{{ accountInfo.one3Count }}</span> 笔</div>
                        <div class="money">优惠金额：<span class="text-len">{{ (accountInfo.one3Sum / 100).toFixed(2) }}</span> 元
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">分佣支出</div>
                        <div class="money">分账笔数：<span class="text-len">{{ accountInfo.one5Count }}</span> 笔</div>
                        <div class="money">分账金额：<span class="text-len">{{ (accountInfo.one5Sum / 100).toFixed(2) }}</span> 元
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">返佣收入</div>
                        <div class="money">收款笔数：<span class="text-len">{{ accountInfo.one4Count }}</span> 笔</div>
                        <div class="money">收款金额：<span class="text-len">{{ (accountInfo.one4Sum / 100).toFixed(2) }}</span> 元
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">购券账单金额</div>
                        <div class="money">购券笔数：<span class="text-len">{{ accountInfo.one2Count }}</span> 笔</div>
                        <div class="money">购券金额：<span class="text-len">{{ (accountInfo.one2Sum / 100).toFixed(2) }}</span> 元
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">实收净额</div>
                        <div class="money-real">{{
                            ((accountInfo.orderAmount + accountInfo.one4Sum + accountInfo.one2Sum - accountInfo.one5Sum) /
                                100).toFixed(2) }}元</div>
                    </div>
                </div>
                <div class="time" style=" margin-top: 20px;">
                    <span>收款时间</span>
                    <el-date-picker v-model="timeArr" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" style="margin: 0 20px;">
                    </el-date-picker>
                    <span style="margin-right: 20px;">支付状态</span>
                    <el-select v-model="getArg.content.tradeState" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已支付" value="SUCCESS"></el-option>
                        <el-option label="未支付" value="NOTPAY"></el-option>
                    </el-select>
                    <span style="margin: 0 20px;">退款状态</span>
                    <el-select v-model="getArg.content.refundStatus" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未退款" value="NOTREFUND"></el-option>
                        <el-option label="已退款" value="SUCCESS"></el-option>
                    </el-select>
                    <el-button type="primary" @click="getData" style="margin-left: 20px;">查询</el-button>
                    <el-button type="primary" @click="print" style="margin-left: 20px;">打印</el-button>
                </div>
                <div class="table">
                    <div style="margin-bottom: 10px;color: #666;">！该表包含普通订单，优惠订单，购券订单</div>
                    <el-table :data="tableData" border style="width: 100%" size="mini">
                        <el-table-column prop="orderId" label="单号" width="200px" align="center">
                        </el-table-column>
                        <el-table-column prop="uid" label="UID" align="center">
                        </el-table-column>
                        <el-table-column label="账单金额(元)" width="120px" align="center">
                            <template slot-scope="v">
                                {{ (v.row.amountTotal / 100).toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="支付金额(元)" width="120px" align="center">
                            <template slot-scope="v">
                                {{ (v.row.amountReal / 100).toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="优惠金额(元)" width="120px" align="center">
                            <template slot-scope="v">
                                {{ ((v.row.amountTotal - v.row.amountReal) / 100).toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="退款金额(元)" width="120px" align="center">
                            <template slot-scope="v">
                                {{ (v.row.amountRefund / 100).toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="支付状态" align="center">
                            <template slot-scope="v">
                                <p
                                    :style="{ 'color': v.row.tradeState == 'NOTPAY' ? '#E6A23C' : v.row.tradeState == 'SUCCESS' ? '#009b4d' : '' }">
                                    {{
                                        v.row.tradeState == "NOTPAY" ? "未支付" : v.row.tradeState == "SUCCESS" ? "支付成功" :
                                        v.row.tradeState }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款状态" align="center">
                            <template slot-scope="v">
                                <p
                                    :style="{ 'color': v.row.refundStatus == '' ? '' : v.row.refundStatus == 'SUCCESS' ? '#E6A23C' : '' }">
                                    {{ v.row.refundStatus == "NOTREFUND" ? "未申请" : v.row.refundStatus == "SUCCESS" ? "退款成功": v.row.refundStatus == "PROCESSING" ? "申请中":v.row.refundStatus }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="timeCreate" label="创建时间" align="center" width="160px">
                            <template slot-scope="v">
                                {{ v.row.timeCreate | timestamp2str }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="250px">
                            <template slot-scope="v">
                                <!-- <el-button type="primary" plain @click="unfreeze(v.row)" size="mini">解冻</el-button> -->
                                <el-button type="primary" plain @click="refresh(v.row)" size="mini">更新</el-button>
                                <el-button type="warning" plain @click="refund(v.row)" size="mini">退款</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
                    </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="分佣收入明细">
                <div class="time">
                    <span>收款时间</span>
                    <el-date-picker v-model="timeArr" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" style="margin: 0 20px;">
                    </el-date-picker>
                    <el-button type="primary" @click="getData" style="margin-left: 20px;">查询</el-button>
                </div>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column label="收入金额(元)" align="center">
                            <template slot-scope="v">
                                {{ v.row.amount / 100 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="timeCreate" label="时间" align="center">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
                    </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="返佣支出明细">
                <div class="time">
                    <span>时间</span>
                    <el-date-picker v-model="timeArr" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" style="margin: 0 20px;">
                    </el-date-picker>
                    <el-button type="primary" @click="getData" style="margin-left: 20px;">查询</el-button>
                </div>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column label="支出金额(元)" align="center">
                            <template slot-scope="v">
                                {{ v.row.amount / 100 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="timeCreate" label="时间" align="center">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
                    </el-pagination>
                </div>
            </el-tab-pane>
            <el-tab-pane label="购买劵包收入">
                <div class="time">
                    <span>收款时间</span>
                    <el-date-picker v-model="timeArr" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" style="margin: 0 20px;">
                    </el-date-picker>
                    <span style="margin-right: 20px;">支付状态</span>
                    <el-select v-model="getArg.content.tradeState" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已支付" value="SUCCESS"></el-option>
                        <el-option label="未支付" value="NOTPAY"></el-option>
                    </el-select>
                    <span style="margin: 0 20px;">退款状态</span>
                    <el-select v-model="getArg.content.refundStatus" placeholder="请选择">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="未退款" value="NOTREFUND"></el-option>
                        <el-option label="已退款" value="SUCCESS"></el-option>
                    </el-select>
                    <el-button type="primary" @click="getData" style="margin-left: 20px;">查询</el-button>
                </div>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">

                        <!-- <el-table-column label="账单金额(元)" width="120px" align="center">
                            <template slot-scope="v">
                                {{ (v.row.amountTotal / 100).toFixed(2) }}
                            </template>
                        </el-table-column> -->
                        <el-table-column label="支付金额(元)" align="center">
                            <template slot-scope="v">
                                {{ (v.row.amountReal / 100).toFixed(2) }}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="优惠金额(元)" width="120px" align="center">
                            <template slot-scope="v">
                                {{ ((v.row.amountTotal - v.row.amountReal) / 100 ).toFixed(2)}}
                            </template>
                        </el-table-column> -->
                        <el-table-column label="退款金额(元)" align="center">
                            <template slot-scope="v">
                                {{ (v.row.amountRefund / 100).toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="支付状态" align="center">
                            <template slot-scope="v">
                                <p
                                    :style="{ 'color': v.row.tradeState == 'NOTPAY' ? '#E6A23C' : v.row.tradeState == 'SUCCESS' ? '#009b4d' : '' }">
                                    {{
                                        v.row.tradeState == "NOTPAY" ? "未支付" : v.row.tradeState == "SUCCESS" ? "支付成功" :
                                        v.row.tradeState }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="退款状态" align="center">
                            <template slot-scope="v">
                                <p
                                    :style="{ 'color': v.row.refundStatus == '' ? '' : v.row.refundStatus == 'SUCCESS' ? '#E6A23C' : '' }">
                                    {{ v.row.refundStatus == "NOTREFUND" ? "未申请" : v.row.refundStatus == "SUCCESS" ? "退款成功"
                                        :
                                        v.row.refundStatus }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="timeCreate" label="创建时间" align="center" width="160px">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="200px">
                            <template slot-scope="v">
                                <el-button type="warning" plain @click="refund(v.row)" size="mini">退款</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page">
                    <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
                    </el-pagination>
                </div>

            </el-tab-pane>
        </el-tabs>
        <el-dialog title="退款" :visible.sync="refundVisible" width="60%">
            <div class="edit-content">
                <el-form ref="addMemForm" label-width="170px">
                    <el-form-item label="订单号">
                        <el-input disabled v-model="refundForm.orderId"></el-input>
                    </el-form-item>
                    <el-form-item label="退款金额">
                        <el-input type="number" :step="0.1" :min="0" v-model="amountRefund" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="退款原因">
                        <el-input v-model="refundForm.reason"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="refundVisible = false">取 消</el-button>
                <el-button type="primary" @click="refundConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getLodop } from '@/assets/js/LodopFuncs'
import { ordergetAccount, orderRefund, orderList, listBuyCoupon, unfreeze, getIncome, disburse, printList, updateTrade } from "@/network/request";
export default {
    data() {
        return {
            refundVisible: false,
            timeArr: null,
            tableData: [],
            amountRefund: null,
            refundForm: {
                orderId: "",
                amount: 0,
                reason: "",
            },
            getArg: {
                page: 1,
                size: 10,
                content: {
                    shopId: null,
                    timeStart: null,
                    timeEnd: null,
                    tradeState: "",
                    refundStatus: ""
                }
            },
            accountInfo: {},
            total: 0,
            tabIndex: "0",
            printerInfo: {
                index: null,
                pageSize: "",
            }
        }
    },
    methods: {
        refresh(v) {
            updateTrade(v.orderId).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.$message.success(data.message)
                    this.getData()
                } else {
                    this.$message.error(data.message)
                }
            })
        },
        print() {
            if (this.timeArr == null) {
                this.getArg.content.timeStart = null
                this.getArg.content.timeEnd = null
            } else {
                this.getArg.content.timeStart = new Date(this.timeArr[0]).getTime()
                this.getArg.content.timeEnd = new Date(this.timeArr[1]).getTime()
            }
            printList(this.getArg).then(res => {
                let data = res.data
                if (data.code==200) {
                    let info = data.data
                    this.LODOP = getLodop()
                    this.LODOP.PRINT_INIT("打印任务名");
                    let pageWidth = parseInt(this.printerInfo.pageSize.split('(')[0] + "0")
                    this.LODOP.SET_PRINT_STYLEA("FontSize", "12");
                    this.LODOP.SET_PRINT_STYLEA("FontName", "黑体");
                    this.LODOP.SET_PRINT_PAGESIZE(3, pageWidth + "mm", 0, "")
                    this.LODOP.ADD_PRINT_TEXT(0, 0, "100%", "10mm", `汇总单`)
                    this.LODOP.SET_PRINT_STYLEA(1, "FontSize", "16");
                    // this.LODOP.SET_PRINT_STYLEA(0, "Horient", 2);
                    this.LODOP.ADD_PRINT_TEXT("10mm", 0, "100%", "6mm", `时间：${info.timeCreate}`)
                    this.LODOP.ADD_PRINT_TEXT("15mm", 0, "100%", "6mm", `账单金额：${info.amountTotal}元`)
                    this.LODOP.ADD_PRINT_TEXT("20mm", 0, "100%", "6mm", `优惠金额：${info.amountDiscount}元`)
                    this.LODOP.ADD_PRINT_TEXT("25mm", 0, "100%", "6mm", `实收金额：${info.amountReal}元`)
                    this.LODOP.ADD_PRINT_TEXT("30mm", 0, "100%", "6mm", `账单数量：${info.count}`)
                    if (info.couponInfo) {
                        this.LODOP.ADD_PRINT_TEXT("35mm", 0, "100%", "12mm", `使用优惠：${info.couponInfo}`)
                    }
                    this.LODOP.PRINT();
                }
            })
        },
        refundConfirm() {
            this.refundForm.amount = this.amountRefund * 100
            if (this.refundForm.orderId == "" || this.refundForm.amount == 0 || this.refundForm.reason == "") {
                this.$message.warning("退款金额和退款原因不可为空！")
                return;
            }
            orderRefund(this.refundForm).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.$message.success(data.message)
                    this.getData()
                } else {
                    this.$message.error(data.message)
                }

            })
            this.refundVisible = false
        },
        refund(v) {
            this.amountRefund = (v.amountReal / 100).toFixed(2)
            this.refundForm.orderId = v.orderId
            this.refundVisible = true
        },
        tabChange(v) {
            this.tableData = []
            this.getArg.page = 1
            this.timeArr = null
            this.getArg.content.tradeState = "SUCCESS"
            this.getArg.content.refundStatus = "NOTREFUND"
            this.tabIndex = v.index
            this.getData()
        },
        getData() {
            if (this.timeArr == null) {
                this.getArg.content.timeStart = null
                this.getArg.content.timeEnd = null
            } else {
                this.getArg.content.timeStart = new Date(this.timeArr[0]).getTime()
                this.getArg.content.timeEnd = new Date(this.timeArr[1]).getTime()
            }
            if (this.tabIndex == "0") {
                orderList(this.getArg).then(res => {
                    let data = res.data
                    if (data.code==200) {
                        this.tableData = data.data.data
                        this.total = data.data.total
                    }
                })
            }
            // 获取佣金收入
            if (this.tabIndex == "1") {
                getIncome(this.getArg).then(res => {
                    let data = res.data
                    if (data.code==200) {
                        this.tableData = data.data.data
                        this.total = data.data.total
                    }
                })
            }
            // 获取佣金支出
            if (this.tabIndex == "2") {
                disburse(this.getArg).then(res => {
                    let data = res.data
                    if (data.code==200) {
                        this.tableData = data.data.data
                        this.total = data.data.total
                    }
                })
            }
            if (this.tabIndex == "3") {
                listBuyCoupon(this.getArg).then(res => {
                    let data = res.data
                    if (data.code==200) {
                        this.tableData = data.data.data
                        this.total = data.data.total
                    }
                })
            }
        },
        unfreeze(v) {
            unfreeze(v.id).then((res) => {
                let data = res.data;
                if (data.code==200) {
                    this.$message.success(data.message);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        pageChange(v) {
            this.getArg.page = v
            this.getData()
        },
        getAccount() {
            ordergetAccount(this.getArg.content.shopId).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.accountInfo = data.data
                }
            })
        }
    },
    created() {
        let tempPrint = JSON.parse(window.localStorage.getItem("printerInfo"))
        if (tempPrint != undefined) {
            this.printerInfo = tempPrint
        }
        this.getArg.content.shopId = JSON.parse(window.localStorage.getItem("shopId"))
        this.getData()
        this.getAccount()
    }

}
</script>

<style lang="less" scoped>
.statement {
    background-color: #fff;
    padding: 20px;

    .cnt {
        display: flex;
        flex-direction: row;

        .item {
            width: 220px;
            height: 120px;
            margin-right: 20px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
            border-radius: 4px;

            .title {
                text-align: center;
                background-color: red;
                font-size: 16px;
                height: 30px;
                line-height: 30px;
                color: #fff;
            }

            .money {
                margin-top: 18px;
                text-align: center;
            }

            .money-real {
                font-weight: bold;
                font-size: 28px;
                color: #ff0000;
                text-align: center;
                line-height: 90px;
            }

            .text-len {
                // border: 1px solid black;
                display: inline-block;
                width: 50px;
                color: #ff0000;
                font-weight: bold;
            }
        }

        &>.item:nth-child(1) {
            .title {
                background-color: #0076fe;
            }
        }

        &>.item:nth-child(2) {
            .title {
                background-color: #724588;
            }
        }

        &>.item:nth-child(3) {
            .title {
                background-color: #e2bb18;
            }
        }

        &>.item:nth-child(4) {
            .title {
                background-color: #e30083;
            }
        }

        &>.item:nth-child(5) {
            .title {
                background-color: #009b4d;
            }
        }

        &>.item:nth-child(6) {
            .title {
                background-color: #61c3d0;
            }
        }
    }

    .table {
        margin-top: 20px;
    }

    .page {
        margin-top: 20px;
        text-align: center;
    }
}
</style>