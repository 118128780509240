<template>
  <div class="vip">
    <div class="search">
      <div style="margin-bottom: 10px;">
        <el-checkbox v-model="searchArg.flag1" :true-label="1" :false-label="0">
          <span>消费时间：</span>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
            :disabled="searchArg.flag1 != 1" end-placeholder="结束日期">
          </el-date-picker>
        </el-checkbox>
      </div>
      <div style="margin-bottom: 10px;">
        <el-checkbox v-model="searchArg.flag2" :true-label="1" :false-label="0">
          <span>消费频次：</span>
          <el-input class="ipt" v-model="searchArg.consumeCountMin" :disabled="searchArg.flag2 != 1" /> &lt; 消费次数 &lt;
          <el-input class="ipt" v-model="searchArg.consumeCountMax" :disabled="searchArg.flag2 != 1" />
        </el-checkbox>

      </div>
      <div style="margin-bottom: 10px;">
        <el-checkbox v-model="searchArg.flag3" :true-label="1" :false-label="0">
          <span>距离上次消费时间：</span>
          <el-input class="ipt" v-model="searchArg.unConsumeDayMin" :disabled="searchArg.flag3 != 1" /> &lt; 未消费天数 &lt;
          <el-input class="ipt" v-model="searchArg.unConsumeDayMax" :disabled="searchArg.flag3 != 1" />
        </el-checkbox>

      </div>
      <div style="margin-bottom: 10px;">
        <el-checkbox v-model="searchArg.flag4" :true-label="1" :false-label="0">
          <span>累积消费金额：</span>
          <el-input class="ipt" v-model="searchArg.consumeAmountMin" :disabled="searchArg.flag4 != 1" /> &lt; 消费金额 &lt;
          <el-input class="ipt" v-model="searchArg.consumeAmountMax" :disabled="searchArg.flag4 != 1" />
        </el-checkbox>
      </div>
      <div style="margin-bottom: 10px;">
        <el-checkbox v-model="searchArg.flag5" :true-label="1" :false-label="0">
          <span>用户ID：</span>
          <el-input v-model="searchArg.uid" :disabled="searchArg.flag5 != 1" class="ipt" />
        </el-checkbox>
      </div>
      <div>
        <el-button type="primary" @click="searchHandler">搜索</el-button>
        <el-button type="success" @click="showVisible">发劵</el-button>
      </div>
    </div>
    <div class="content">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="uid" label="用户ID" align="center">
        </el-table-column>
        <el-table-column prop="nickname" label="昵称" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="电话号码" align="center">
        </el-table-column>
        <el-table-column prop="birthday" label="生日" align="center" width="100px">
          <template slot-scope="v">
            <p>{{ v.row.birthday ? v.row.birthday.substring(0, 10) : "" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="consumeSum" label="累计消费总额（元）" align="center" width="110px">
          <template slot-scope="v">
            {{ (v.row.consumeSum/100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="consumeLatestTime" label="最新消费时间" align="center" width="210px">
        </el-table-column>
        <el-table-column prop="consumeRequency" label="累积消费次数" align="center" width="110px">
        </el-table-column>
        <!-- <el-table-column label="操作" align="center" width="180px">
          <template slot-scope="v">
            <el-button type="primary" plain @click="gotoDetails(v.row)">详情</el-button>
            <el-button type="success" plain>发劵</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="page">
      <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
      </el-pagination>
    </div>

    <el-dialog title="选择优惠券" :visible.sync="visible" width="1300px">
      <el-checkbox-group v-model="sendArg.couponIds" class="coupon-cnt">
        <el-checkbox :label="item.id" v-for="(item, index) in list" :key="index">
          <couponCus :item="item"></couponCus>
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="handlerConfirm">确认</el-button>
      </span>
    </el-dialog>

  </div>
</template>
  
<script>
import {
  userSearch,
   userSearchUids,
  sendTicket, couponList
} from '@/network/request'
import couponCus from '@/components/coupon-cus.vue';
export default {
  components: { couponCus },
  data() {
    return {
      visible: false,
      value1: [],
      arg: {
        page: 1,
        size: 10,
        content: {}
      },
      total: 0,
      searchArg: {
        flag1: 0,
        flag2: 0,
        flag3: 0,
        flag4: 0,
        flag5: 0,
        consumeTimeStart: null,
        consumeTimeEnd: null,
        consumeCountMin: null,
        consumeCountMax: null,
        unConsumeDayMin: null,
        unConsumeDayMax: null,
        consumeAmountMin: null,
        consumeAmountMax: null,
        uid: null
      },
      tableData: [
      ],
      sendArg: {
        shopId: null,
        couponIds: [],
        uids: []
      },
      getArg: {
        page: 1,
        size: 10,
        content: {
          shopId: null,
          classify: null,
        }
      },
      list: []
    }
  },
  methods: {
    showVisible() {
      this.visible = true
    },
    handlerConfirm() {
      sendTicket(this.sendArg).then((res) => {
        // console.log(res);
        this.visible = false
        let data = res.data;
        if (data.code==200) {
          this.$message.success(data.message);
        } else {
          this.$message.error(data.message);
        }
      })
    },
    searchHandler(){
      this.arg.page=1
      this.search()
    },
    search() {
      if (this.searchArg.flag1 == 1) {
        this.searchArg.consumeTimeStart = this.value1[0].getTime()
        this.searchArg.consumeTimeEnd = this.value1[1].getTime()
      }
      for (let key in this.searchArg) {
        if (this.searchArg[key] != null) {
          this.searchArg[key] = parseInt(this.searchArg[key])
        }
      }
      this.arg.content = this.searchArg
      userSearch(this.arg).then(res => {
        let data = res.data
        if (data.code==200) {
          this.tableData = data.data.data
          this.total = data.data.total
        }
      })
      userSearchUids(this.arg).then(res => {
        let data = res.data
        if (data.code==200) {
          let temp = data.data
          this.sendArg.uids = temp
        }
      })
    },
    gotoDetails(v) {
      console.log(v);
    },
    pageChange(v) {
      this.arg.page = v
      this.search()
    },
    // 获取所有优惠券
    getData() {
      couponList(this.getArg).then(res => {
        let data = res.data
        if (data.code==200) {
          this.list = data.data.data
          this.total = data.data.total
        }
      })
    },
    confirmCoupon() { }
  },
  created() {
    let shopId = window.localStorage.getItem("shopId")
    this.sendArg.shopId = parseInt(shopId)
    this.getArg.content.shopId = this.sendArg.shopId
    this.getData()
    this.search()
  }
}
</script>
  
<style lang="less" scoped>
.vip {
  background-color: #fff;
  padding: 20px;

  .search {
    margin-bottom: 20px;

    .ipt {
      width: 80px;
    }
  }

  .page {
    margin-top: 20px;
    text-align: center;
  }

}
</style>