import axios from "./Interceptor"


// export const BASE_URL = "http://127.0.0.1:7000"
// export const BASE_URL = "http://192.168.2.22:10000"
export const BASE_URL = "https://2473j620d4.zicp.fun"
// export const BASE_URL = "https://www.beect.cn/micro"



// 店铺注册
export function registerAdd(arg) {
    return axios.post(BASE_URL + "/shop/shopRegister/add", arg)
}

export function getShopInfo(shopId) {
    return axios.get(BASE_URL + `/shop/shopRegister/getInfoByShopId/${shopId}`)
}

// 店铺信息修改
export function registerEdit(arg) {
    return axios.post(BASE_URL + "/shop/shopRegister/edit", arg)
}

// 登录
export function login(arg) {
    return axios.post(BASE_URL + "/user/user/webLogin", arg)
}

export function editPass(arg) {
    return axios.post(BASE_URL + "/user/user/editPass", arg)
}

export function changeShop(shopId) {
    return axios.get(BASE_URL + "/user/user/changeShop/" + shopId)
}

export function employeeList(arg) {
    return axios.post(BASE_URL + "/shop/shopAdmin/list", arg)
}
export function employeeAddOrSave(arg) {
    return axios.post(BASE_URL + "/shop/shopAdmin/add", arg)
}
export function employeeDel(id) {
    return axios.get(BASE_URL + "/shop/shopAdmin/del/" + id)
}

// 获取验证码
export function getMessage(code) {
    return axios.get(BASE_URL + "/user/user/getMsgCode/" + code)
}

export function userSearch(arg) {
    return axios.post(BASE_URL + "/user/user/search", arg)
}

export function userSearchUids(arg) {
    return axios.post(BASE_URL + "/user/user/searchUids", arg)
}

export function couponAdd(arg) {
    return axios.post(BASE_URL + "/activity/coupon/add", arg)
}

export function couponList(arg) {
    return axios.post(BASE_URL + "/activity/coupon/list", arg)
}
export function couponGetById(id) {
    return axios.get(BASE_URL + `/activity/coupon/getById/${id}`)
}
export function couponDel(shopId, id) {
    return axios.get(BASE_URL + `/activity/coupon/del/${shopId}/${id}`)
}

export function activityList(arg) {
    return axios.post(BASE_URL + "/activity/activity/list", arg)
}

export function activityAdd(arg) {
    return axios.post(BASE_URL + "/activity/activity/add", arg)
}

export function activityEdit(arg) {
    return axios.post(BASE_URL + "/activity/activity/edit", arg)
}

export function activityGetById(id) {
    return axios.get(BASE_URL + `/activity/activity/getById/${id}`)
}

export function activityDel(id) {
    return axios.get(BASE_URL + `/activity/activity/del/${id}`)
}


export function shopSortList() {
    return axios.get(BASE_URL + `/biz/shopSort/listAll`)
}

export function shopApplymentAdd(arg) {
    return axios.post(BASE_URL + "/shop/shopApplyment/add", arg)
}

export function shopApplymentList(arg) {
    return axios.post(BASE_URL + "/shop/shopApplyment/list", arg)
}

export function shopApplymentGetByShopId(shopId) {
    return axios.get(BASE_URL + `/shop/shopApplyment/getByShopId/${shopId}`)
}

export function shopApplymentSubmit(id) {
    return axios.get(BASE_URL + `/shop/shopApplyment/submit/${id}`)
}

export function systemPermissionsListAll() {
    return axios.get(BASE_URL + `/user/systemPermissions/listAll/1`)
}

export function systemRoletList(arg) {
    return axios.post(BASE_URL + "/user/systemRole/list", arg)
}

export function getRoleList() {
    return axios.get(BASE_URL + "/user/systemRole/getRoleList")
}

export function systemRoleAddOrSave(arg) {
    return axios.post(BASE_URL + "/user/systemRole/addOrEditRole", arg)
}

export function systemRoleDel(rid) {
    return axios.get(BASE_URL + `/user/systemRole/del/${rid}`)
}

export function orderList(arg) {
    return axios.post(BASE_URL + "/order/order/list", arg)
}
export function printList(arg) {
    return axios.post(BASE_URL + "/order/order/printList", arg)
}

export function orderRefund(arg) {
    return axios.post(BASE_URL + `/order/order/refund`, arg)
}

export function listBuyCoupon(arg) {
    return axios.post(BASE_URL + "/order/order/listBuyCoupon", arg)
}

export function ordergetAccount(shopId) {
    return axios.get(BASE_URL + "/order/order/getAccount/" + shopId)
}

export function updateTrade(orderId) {
    return axios.get(BASE_URL + "/order/order/updateTrade/" + orderId)
}

export function getPrintInfo(orderId) {
    return axios.get(BASE_URL + "/order/order/getPrintInfo/" + orderId)
}

export function getTurnoverHour(shopId) {
    return axios.get(BASE_URL + "/order/order/getTurnoverHour/" + shopId)
}

export function getOrderAmountHour(shopId) {
    return axios.get(BASE_URL + "/order/order/getOrderAmountHour/" + shopId)
}

export function getTurnoverDay(shopId) {
    return axios.get(BASE_URL + "/order/order/getTurnoverDay/" + shopId)
}

export function getOrderAmountDay(shopId) {
    return axios.get(BASE_URL + "/order/order/getOrderAmountDay/" + shopId)
}

export function unfreeze(id) {
    return axios.get(BASE_URL + "/order/order/unfreeze/" + id)
}

export function activityChangeStatus(id, status) {
    return axios.get(BASE_URL + `/activity/activity/changeStatus/${id}/${status}`)
}


export function sendTicket(arg) {
    return axios.post(BASE_URL + "/activity/userCoupon/sendTicket", arg)
}

export function shopAdvAddOrSave(arg) {
    return axios.post(BASE_URL + "/shop/shopAdv/addOrSave", arg)
}

export function shopAdvList(arg) {
    return axios.post(BASE_URL + "/shop/shopAdv/list", arg)
}

export function shopAdvDel(id) {
    return axios.get(BASE_URL + `/shop/shopAdv/del/${id}`)
}


export function analysis(arg) {
    return axios.post(BASE_URL + "/activity/userCoupon/analysis", arg)
}


export function getIncome(arg) {
    return axios.post(BASE_URL + "/shop/profitSharingReceiver/getIncome", arg)
}

export function disburse(arg) {
    return axios.post(BASE_URL + "/shop/profitSharingReceiver/disburse", arg)
}

export function shopPartnerList(arg) {
    return axios.post(BASE_URL + "/shop/shopPartner/list", arg)
}

export function shopPartnerAdd(arg) {
    return axios.post(BASE_URL + "/shop/shopPartner/add", arg)
}

export function shopPartnerDel(id) {
    return axios.get(BASE_URL + `/shop/shopPartner/del/${id}`)
}

export function shopPartnerSearch(shopId) {
    return axios.get(BASE_URL + `/shop/shopPartner/search/${shopId}`)
}