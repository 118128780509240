<template>
    <div ref="note-temp">
        <div style="line-height: 1.5;width: 250px;font-family:'黑体';" class="note-temp">
            <div style="display: flex;flex-direction: row;">
                <span>订单号：</span>
                <span style="flex: 1;">{{ item.orderId }}</span>
            </div>
            <div style="display: flex;flex-direction: row;">
                <span>订单时间：</span>
                <span>{{ item.timeCreate }}</span>
            </div>
            <div style="display: flex;flex-direction: row;">
                <span>账单金额：</span>
                <span>{{ item.amountTotal }}元</span>
            </div>
            <div style="display: flex;flex-direction: row;">
                <span>优惠金额：</span>
                <span>{{ item.amountDiscount }}元</span>
            </div>
            <div style="display: flex;flex-direction: row;">
                <span>实收金额：</span>
                <span>{{ item.amountReal }}元</span>
            </div>
            <div style="display: flex;flex-direction: row;" v-if="item.couponInfo">
                <span>使用优惠：</span>
                <span style="flex: 1;">{{ item.couponInfo }}</span>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
    }
}
</script>

<style>
.note-temp {
    border: 1px solid #DCDFE6;
    padding: 10px 20px;
}
</style>