<template>
    <div class="pay-adv">
        <div class="search">
            <el-button type="success" @click="add"  size="small">新增</el-button>
        </div>
        <div class="content">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="广告图" align="center">
                    <template slot-scope="v">
                        <el-image style="width: 90px; height: 50px" :src="v.row.photoUrl"
                            :preview-src-list="[v.row.photoUrl]" fit="fit"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="sequence" label="排序" align="center">
                </el-table-column>
                <el-table-column prop="instruction" label="广告说明" align="center">
                </el-table-column>
                <el-table-column label="创建时间" align="center">
                    <template slot-scope="v">{{v.row.timeCreate | timestamp2str }}</template>
                </el-table-column>
                <el-table-column prop="uidCreate" label="创建人" align="center">
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="v">
                        <el-button type="warning" plain @click="edit(v.row)">修改</el-button>
                        <el-popconfirm style="margin-left: 10px;" title="是否确认删除" @confirm="delConfirm(v.row)">
                            <el-button slot="reference" type="danger" plain>删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination background layout="total,prev, pager, next" :total="total" @current-change="pageChange">
            </el-pagination>
        </div>
        <el-dialog :title="title" :visible="visible" width="60%">
            <div class="edit-content">
                <el-form ref="advForm" :model="form" label-width="100px">
                    <el-form-item label="广告图">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/biz/common/uploadImg'" :headers="headers" :show-file-list="false"
                            :data="dataForm" :on-success="uploadSuccess">
                            <img v-if="form.photoUrl" :src="form.photoUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>

                        <!-- <el-upload class="avatar-uploader" :data="uploadData" :headers="headers"
                            :action="BASE_URL + '/biz/common/uploadImg'" :show-file-list="false" :on-success="uploadSuccess"
                            :before-upload="logoBefore">
                            <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload> -->

                    </el-form-item>
                    <el-form-item label="排序">
                        <el-input-number v-model="form.sequence"></el-input-number>
                    </el-form-item>
                    <el-form-item label="广告描述">
                        <el-input v-model="form.instruction"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="handlerConfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { BASE_URL, shopAdvAddOrSave, shopAdvList, shopAdvDel } from "@/network/request";
export default {
    data() {
        return {
            headers:{token:""},
            visible: false,
            title: "新增",
            BASE_URL: BASE_URL,
            form: {
                // shopId: null,
                // instruction: null,
                // rank: null,
                // // photoUrl: '',
            },
            tableData: [],
            getArg: {
                page: 1,
                size: 10,
                content: {
                    shopId: null
                }
            },
            total: 0,
            dataForm: {
                shopId: 0,
                classify: 2
            }
        }
    },
    methods: {
        getData() {
            shopAdvList(this.getArg).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.tableData = data.data.data
                    this.total = data.data.total
                }
            })
        },
        pageChange(v) {
            this.getArg.page = v
            this.getData()
        },
        uploadSuccess(v) {
            this.form.photoUrl = v.data
        },
        add() {
            this.title = "新增"
            this.form = {}
            this.visible = true
        },
        edit(v) {
            this.title = "修改"
            this.form = v
            this.visible = true
        },
        delConfirm(v) {
            shopAdvDel(v.id).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.$message.success("删除成功！")
                } else {
                    this.$message.error(data.message)
                }
                this.visible = false
                this.getData()
            })
        },
        handlerConfirm() {
            this.form.shopId = this.getArg.content.shopId
            shopAdvAddOrSave(this.form).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.$message.success(data.message)
                } else {
                    this.$message.error(data.message)
                }
                this.visible = false
                this.getData()
            })
        }
    },
    created() {
        this.getArg.content.shopId =parseInt(window.localStorage.getItem("shopId"))
        this.dataForm.shopId = this.getArg.content.shopId
        this.getData()
        this.headers.token=window.localStorage.getItem("token")
    }
}
</script>

<style lang="less">
.pay-adv {
    background-color: #fff;
    padding: 20px;

    .content {
        margin-top: 20px;
    }

    .page {
        margin-top: 20px;
        text-align: center;
    }

    .edit-content {
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
        }

        .avatar {
            width: 80px;
            height: 80px;
            display: block;
        }
    }
}
</style>