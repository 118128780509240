<template>
    <div class="shop-add">
        <div class="title">进件申请</div>
        <div class="body">
            <!-- <div class="content">
                <div class="title">申请单信息</div>
            </div> -->
            <div class="must-info">
                <div class="headline">主体类型</div>
                <div class="cnt">
                    <div class="item">
                        <el-select v-model="form.subjectType" clearable placeholder="请选择主体类型" @change="change"
                            style="margin: 0 20px;">
                            <el-option label="个体户" value="SUBJECT_TYPE_INDIVIDUAL"></el-option>
                            <el-option label="企业" value="SUBJECT_TYPE_ENTERPRISE"></el-option>
                            <el-option label="政府机关" value="SUBJECT_TYPE_GOVERNMENT"></el-option>
                            <el-option label="事业单位" value="SUBJECT_TYPE_INSTITUTIONS"></el-option>
                            <el-option label="社会组织" value="SUBJECT_TYPE_OTHERS"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="must-info">
                <div class="headline">必填信息</div>
                <div class="cnt">
                    <div class="item" v-for="(item, index) in list" :key="index" @click="goto(item)">
                        <div :class="['box', current == item.id ? 'active' : '']"></div>
                        <div class="right">
                            <div class="left">
                                <div class="title">{{ item.title }}</div>
                                <div class="desc">{{ item.desc }}</div>
                            </div>
                            <div class="action">去填写></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="title">选填信息</div>
                <div class="cnt">
                    <div class="left">
                        <div class="headline">补充资料</div>
                        <div class="desc">根据实际审核情况，会额外要求商家提供指定的补充资料</div>
                    </div>
                    <div class="right">去填写></div>
                </div>
            </div>
            <div class="submit">
                <el-button type="info" @click="back">返回</el-button>
                <el-button type="success">提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { shopApplymentGetByShopId,shopApplymentAdd } from "@/network/request";
export default {
    data() {
        return {
            list: [
                {
                    id: 1,
                    title: "超级管理员信息",
                    desc: "超级管理员需在开户后进行签约，并可接收日常重要管理信息和进行资金操作，请确定其为商户法定代表人或负责人。"
                },
                {
                    id: 2,
                    title: "主体资料",
                    desc: "请填写商家的营业执照/登记证书、经营者/法人的证件等信息"
                },
                {
                    id: 3,
                    title: "经营资料",
                    desc: "请填写商家的经营业务信息、售卖商品/提供服务场景信息"
                },
                // {
                //     id: 4,
                //     title: "结算规则",
                //     desc: "请填写商家的结算费率规则、特殊资质等信息"
                // },
                {
                    id: 5,
                    title: "结算银行账户",
                    desc: "请填写商家提现收款的银行账户信息"
                }
            ],
            current: 1,
            form: {
                shopId: null,
                subjectType:""
            }
        }
    },
    methods: {
        getData(){
            shopApplymentGetByShopId(this.form.shopId).then(res=>{
                let data=res.data
                if(data.code==200){
                    this.form=data.data
                }else{
                    this.$message.warning(data.message)
                }
            })
        },
        change() {
            shopApplymentAdd(this.form).then(res=>{
                let data=res.data
                if(data.code==200){
                    this.getData()
                    this.$message.success("更改主体类型成功！");
                }else{
                    this.$message.error("更改主体类型成功！");
                }
            })
        },
        back() {
            this.$router.go(-1)
        },
        goto(item) {
            if(this.form.subjectType==""){
                this.$message.error("请先选择主体类型！");
                return
            }
            this.$router.push("/addSubject/" + item.id + "/" + this.form.subjectType)
        }
    },
    created() {
        this.form.shopId = window.localStorage.getItem("shopId");
        this.getData()
    },
}
</script>

<style lang="less" scoped>
.shop-add {
    background-color: #fff;
    padding: 20px;

    .content {
        .title {
            margin: 30px 0;
            padding-left: 10px;
            border-left: red solid 4px;
        }

        .headline {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 8px;
        }

        .cnt {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .right {
                cursor: pointer;
            }
        }




    }

    .must-info {
        .headline {
            margin: 30px 0;
            padding-left: 10px;
            border-left: red solid 4px;
        }

        .item {
            position: relative;
            display: flex;
            flex-direction: row;

            .box {
                background-color: #ffffff;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                position: relative;
            }

            .box::after {
                content: "";
                width: 8px;
                height: 8px;
                background-color: #e5e5e5;
                position: absolute;
                left: 50%;
                top: 50%;
                border-radius: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }

            .active {
                background-color: #b3d8ff;
            }

            .active::after {
                background-color: #409EFF;
            }

            .right {
                padding-left: 10px;
                flex: 1;
                height: 80px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;


                .title {
                    font-weight: bold;
                    font-size: 16px;
                    margin-bottom: 8px;
                }

                .action {
                    cursor: pointer;

                }

                .desc {}
            }
        }

        .cnt>:not(:last-child)::after {
            content: "";
            width: 2px;
            height: 50px;
            background: #f8f8f8;
            position: absolute;
            left: 8px;
            top: 20px;

        }
    }

    .submit {
        margin-top: 30px;
    }
}
</style>