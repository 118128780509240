<template>
    <div class="add-subject">
        <div class="titlee">{{ title }}</div>
        <div class="content" v-if="type == 1">
            <div class="item">
                <div class="title">超级管理员信息</div>
                <el-form :rules="rules" ref="addForm" :model="addForm" label-width="80px">
                    <el-form-item label="类型" prop="contactType">
                        <el-radio-group v-model="addForm.contactType">
                            <el-radio label="LEGAL">经营者/法人</el-radio>
                            <el-radio label="SUPER">经办人</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="姓名" prop="contactName">
                        <el-input v-model="addForm.contactName" />
                    </el-form-item>
                    <el-form-item label="证件类型" v-if="addForm.contactType == 'SUPER'">
                        <el-select v-model="addForm.contactIdDocType" clearable placeholder="当超级管理员类型是经办人时，请上传超级管理员证件类型。"
                            style="width: 100%;">
                            <el-option label="中国大陆居民-身份证" value="IDENTIFICATION_TYPE_IDCARD"></el-option>
                            <el-option label="其他国家或地区居民-护照" value="IDENTIFICATION_TYPE_OVERSEA_PASSPORT"></el-option>
                            <el-option label="中国香港居民-来往内地通行证" value="IDENTIFICATION_TYPE_HONGKONG_PASSPORT"></el-option>
                            <el-option label="中国澳门居民-来往内地通行证" value="IDENTIFICATION_TYPE_MACAO_PASSPORT"></el-option>
                            <el-option label="中国台湾居民-来往大陆通行证" value="IDENTIFICATION_TYPE_TAIWAN_PASSPORT"></el-option>
                            <el-option label="外国人居留证" value="IDENTIFICATION_TYPE_FOREIGN_RESIDENT"></el-option>
                            <el-option label="港澳居民证" value="IDENTIFICATION_TYPE_HONGKONG_MACAO_RESIDENT"></el-option>
                            <el-option label="台湾居民证" value="IDENTIFICATION_TYPE_TAIWAN_RESIDENT"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份证件号码" v-if="addForm.contactType == 'SUPER'">
                        <el-input v-model="addForm.contactIdNumber" placeholder="当超级管理员类型是经办人时，请上传超级管理员证件号码。" />
                    </el-form-item>
                    <el-form-item label="证件正面照片" v-if="addForm.contactType == 'SUPER'">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                            :show-file-list="false" :on-success="adminIdCardSuccess">
                            <img v-if="addForm.contactIdDocCopy" :src="contactIdDocCopy" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="tips">！当超级管理员类型是经办人时，请上传超级管理员证件的正面照片。</div>
                    </el-form-item>
                    <el-form-item label="证件反面照片" v-if="addForm.contactType == 'SUPER'">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                            :show-file-list="false" :on-success="adminIdCardBackSuccess">
                            <img v-if="addForm.contactIdDocCopyBack" :src="contactIdDocCopyBack" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="tips">！当超级管理员类型是经办人时，请上传超级管理员证件的反面照片。</div>
                    </el-form-item>
                    <el-form-item label="证件生效日期" v-if="addForm.contactType == 'SUPER'">
                        <el-date-picker type="date" placeholder="选择日期" v-model="tempData.contactPeriodBegin"
                            @change="contactPeriodBeginChange" style="width: 100%;"></el-date-picker>
                        <div class="tips">！当超级管理员类型是经办人时，请上传证件生效日期。</div>
                    </el-form-item>
                    <el-form-item label="证件失效日期" v-if="addForm.contactType == 'SUPER'">
                        <el-date-picker type="date" placeholder="选择日期" v-model="tempData.contactPeriodEnd"
                            @change="contactPeriodEndChange" style="width: 100%;"></el-date-picker>
                        <div class="tips">！当超级管理员类型是经办人时，请上传证件失效日期。</div>
                    </el-form-item>
                    <el-form-item label="业务办理授权函" v-if="addForm.contactType == 'SUPER'">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                            :show-file-list="false" :on-success="businessAuthSuccess">
                            <img v-if="addForm.businessAuthorizationLetter" :src="tempData.businessAuthorizationLetter"
                                class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <div class="tips">！当超级管理员类型是经办人时，请上传授权函，请参照["<a
                                href="https://kf.qq.com/faq/220509Y3Yvym220509fQvYR7.html"
                                target="_blank">示例图</a>"]打印业务办理授权函，全部信息需打印，不支持手写商户信息，并加盖公章。</div>
                    </el-form-item>
                    <el-form-item label="微信OpenID" v-if="addForm.contactType == 'SUPER'">
                        <el-input v-model="addForm.openid" />
                    </el-form-item>
                    <el-form-item label="联系手机" prop="mobilePhone">
                        <el-input v-model="addForm.mobilePhone" />
                    </el-form-item>
                    <el-form-item label="联系邮箱" prop="contactEmail">
                        <el-input v-model="addForm.contactEmail" />
                    </el-form-item>

                </el-form>
            </div>
        </div>
        <div class="content" v-if="type == 2">
            <div class="item">
                <div class="title">营业执照</div>
                <el-form ref="addForm" :rules="rules" :model="businessLicenseInfo" label-width="80px">
                    <el-form-item label="营业执照照片" prop="licenseCopy">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                            :show-file-list="false" :on-success="licenseCopyUploadSuccess">
                            <img v-if="tempData.licenseCopy" :src="tempData.licenseCopy" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="统一社会信用代码" prop="licenseNumber">
                        <el-input v-model="businessLicenseInfo.licenseNumber" />
                    </el-form-item>
                    <el-form-item label="商户名称" prop="merchantName">
                        <el-input v-model="businessLicenseInfo.merchantName" />
                    </el-form-item>
                    <el-form-item label="法人姓名" prop="legalPerson">
                        <el-input v-model="businessLicenseInfo.legalPerson" />
                    </el-form-item>
                    <el-form-item label="注册地址">
                        <el-input v-model="businessLicenseInfo.licenseAddress" />
                    </el-form-item>
                    <el-form-item label="有效期限开始日期">
                        <el-input v-model="businessLicenseInfo.periodBegin" placeholder="格式：YYYY-MM-DD" />
                    </el-form-item>
                    <el-form-item label="有效期限结束日期">
                        <el-input v-model="businessLicenseInfo.periodEnd" placeholder="格式：YYYY-MM-DD" />
                    </el-form-item>
                </el-form>
            </div>
            <div class="item"
                v-if="addForm.subjectType == 'SUBJECT_TYPE_GOVERNMENT' || form.subjectType == 'SUBJECT_TYPE_INSTITUTIONS'">
                <div class="title">登记证书</div>
                <el-form ref="addForm" :rules="rules" :model="certificateInfo" label-width="80px">
                    <el-form-item label="登记证书照片" prop="certCopy">
                        <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                            :data="dataForm" :show-file-list="false" :on-success="handleAvatarSuccess">
                            <img v-if="certificateInfo.certCopy" :src="certificateInfo.certCopy" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="登记证书类型" prop="certType">
                        <el-select v-model="certificateInfo.certType" clearable
                            placeholder="主体为“政府机关/事业单位/社会组织”时，请上传登记证书类型。" style="width: 100%;">
                            <el-option label="事业单位法人证书" value="CERTIFICATE_TYPE_2388"></el-option>
                            <el-option label="统一社会信用代码证书" value="CERTIFICATE_TYPE_2389"></el-option>
                            <el-option label="社会团体法人登记证书" value="CERTIFICATE_TYPE_2394"></el-option>
                            <el-option label="民办非企业单位登记证书" value="CERTIFICATE_TYPE_2395"></el-option>
                            <el-option label="基金会法人登记证书" value="CERTIFICATE_TYPE_2396"></el-option>
                            <el-option label="执业许可证/执业证" value="CERTIFICATE_TYPE_2520"></el-option>
                            <el-option label="基层群众性自治组织特别法人统一社会信用代码证" value="CERTIFICATE_TYPE_2521"></el-option>
                            <el-option label="农村集体经济组织登记证" value="CERTIFICATE_TYPE_2522"></el-option>
                            <el-option label="宗教活动场所登记证" value="CERTIFICATE_TYPE_2399"></el-option>
                            <el-option label="政府部门下发的其他有效证明文件" value="CERTIFICATE_TYPE_2400"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="证书号" prop="certNumber">
                        <el-input v-model="certificateInfo.certNumber" />
                    </el-form-item>
                    <el-form-item label="商户名称" prop="merchantName">
                        <el-input v-model="certificateInfo.merchantName" />
                    </el-form-item>
                    <el-form-item label="注册地址" prop="companyAddress">
                        <el-input v-model="certificateInfo.companyAddress" />
                    </el-form-item>
                    <el-form-item label="法定代表人" prop="legalPerson">
                        <el-input v-model="certificateInfo.legalPerson" />
                    </el-form-item>
                    <el-form-item label="有效期限开始日期" prop="periodBegin">
                        <el-input v-model="certificateInfo.periodBegin" placeholder="格式：YYYY-MM-DD" />
                    </el-form-item>
                    <el-form-item label="有效期限结束日期" prop="periodEnd">
                        <el-input v-model="certificateInfo.periodEnd" placeholder="格式：YYYY-MM-DD" />
                    </el-form-item>

                </el-form>
            </div>
            <div class="item">
                <div class="title">经营者/法人身份证件</div>
                <el-form ref="addForm" :rules="rules" :model="identityInfo" label-width="80px">
                    <el-form-item label="证件类型">
                        <el-select v-model="identityInfo.idDocType" clearable placeholder="当证件持有人类型为法人时，填写。其他情况，无需上传"
                            style="width: 100%;">
                            <el-option label="中国大陆居民-身份证" value="IDENTIFICATION_TYPE_IDCARD"></el-option>
                            <el-option label="其他国家或地区居民-护照" value="IDENTIFICATION_TYPE_OVERSEA_PASSPORT"></el-option>
                            <el-option label="中国香港居民-来往内地通行证" value="IDENTIFICATION_TYPE_HONGKONG_PASSPORT"></el-option>
                            <el-option label="中国澳门居民-来往内地通行证" value="IDENTIFICATION_TYPE_MACAO_PASSPORT"></el-option>
                            <el-option label="中国台湾居民-来往大陆通行证" value="IDENTIFICATION_TYPE_TAIWAN_PASSPORT"></el-option>
                            <el-option label="外国人居留证" value="IDENTIFICATION_TYPE_FOREIGN_RESIDENT"></el-option>
                            <el-option label="港澳居民证" value="IDENTIFICATION_TYPE_HONGKONG_MACAO_RESIDENT"></el-option>
                            <el-option label="台湾居民证" value="IDENTIFICATION_TYPE_TAIWAN_RESIDENT"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份证人像面照片" prop="idCardCopy">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                            :show-file-list="false" :on-success="idCardCopyUploadSuccess">
                            <img v-if="tempData.idCardCopy" :src="tempData.idCardCopy" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="身份证国徽面照片" prop="idCardNational">
                        <el-upload class="avatar-uploader" :action="BASE_URL + '/common/wxUploadImg'" :data="dataForm"
                            :show-file-list="false" :on-success="idCardNationalUploadSuccess">
                            <img v-if="tempData.idCardNational" :src="tempData.idCardNational" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="身份证号码" prop="idCardNumber">
                        <el-input v-model="idCardInfo.idCardNumber" />
                    </el-form-item>
                    <el-form-item label="身份证姓名" prop="idCardName">
                        <el-input v-model="idCardInfo.idCardName" />
                    </el-form-item>
                    <el-form-item label="身份证居住地址">
                        <el-input v-model="idCardInfo.idCardAddress" placeholder="主体类型为企业时，需要填写。其他主体类型，无需上传。" />
                    </el-form-item>
                    <el-form-item label="有效期限开始日期" prop="cardPeriodBegin">
                        <el-input v-model="idCardInfo.cardPeriodBegin" placeholder="格式：YYYY-MM-DD" />
                    </el-form-item>
                    <el-form-item label="有效期限结束日期" prop="cardPeriodEnd">
                        <el-input v-model="idCardInfo.cardPeriodEnd" placeholder="格式：YYYY-MM-DD" />
                    </el-form-item>
                    <el-form-item label="是否为受益人" v-if="addForm.subjectType == 'SUBJECT_TYPE_ENTERPRISE'">
                        <el-radio-group v-model="identityInfo.owner">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                        <div class="tips">
                            ！主体类型为企业时，需要填写
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="content" v-if="type == 3">
            <div class="item">
                <div class="title">经营资料</div>
                <el-form ref="addForm" :rules="rules" :model="addForm" label-width="100px">
                    <el-form-item label="商户简称" prop="merchantShortname">
                        <el-input v-model="addForm.merchantShortname" />
                    </el-form-item>

                    <el-form-item label="客服电话" prop="servicePhone">
                        <el-input v-model="addForm.servicePhone" />
                    </el-form-item>
                </el-form>
            </div>
            <div class="item">
                <div class="title">经营场景</div>
                <el-form ref="salesInfo" :model="salesInfo" label-width="100px">
                    <el-form-item label="经营场景类型">
                        <el-select v-model="salesInfo.salesScenesType" clearable placeholder="请选择经营场景类型">
                            <el-option label="线下场所" value="SALES_SCENES_STORE"></el-option>
                            <el-option label="小程序" value="SALES_SCENES_MINI_PROGRAM"></el-option>
                            <!-- <el-option label="公众号" value="SALES_SCENES_MP"></el-option>
                            <el-option label="互联网网站" value="SALES_SCENES_WEB"></el-option>
                            <el-option label="APP" value="SALES_SCENES_APP"></el-option>
                            <el-option label="企业微信" value="SALES_SCENES_WEWORK"></el-option> -->
                        </el-select>
                    </el-form-item>
                </el-form>
                <div v-if="salesInfo.salesScenesType == 'SALES_SCENES_STORE'">
                    <el-form ref="addForm" :model="bizStoreInfo" :rules="rules" label-width="100px">

                        <el-form-item label="线下场所名称" prop="bizStoreName">
                            <el-input v-model="bizStoreInfo.bizStoreName" placeholder="请填写门店名称，如大郎烧饼" />
                        </el-form-item>

                        <el-form-item label="线下场所省市编码" prop="bizAddressCode">
                            <el-input v-model="bizStoreInfo.bizAddressCode" placeholder="请填写省市编码，如440305" />
                        </el-form-item>

                        <el-form-item label="线下场所地址" prop="bizStoreAddress">
                            <el-input v-model="bizStoreInfo.bizStoreAddress"
                                placeholder="请填写详细的经营场所信息，如有多个场所，选择一个主要场所填写即可。" />
                        </el-form-item>
                        <el-form-item label="线下场所门头照片" prop="storeEntrancePic">
                            <el-upload class="upload-demo" :action="BASE_URL + '/common/wxUploadImg'" :multiple="true"
                                :file-list="tempData.storeEntrancePic" :on-success="storeEntrancePicUploadSuccess"
                                :data="dataForm" :on-remove="storeEntrancePicRemove" :limit="5" list-type="picture-card">
                                <i slot="default" class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,最多1张</div>
                            </el-upload>

                        </el-form-item>
                        <el-form-item label="线下场所内部照片" prop="indoorPic">
                            <el-upload class="upload-demo" :action="BASE_URL + '/common/wxUploadImg'" :multiple="true"
                                :file-list="tempData.indoorPic" :on-success="indoorPicUploadSuccess" :data="dataForm"
                                :on-remove="indoorPicRemove" :limit="5" list-type="picture-card">
                                <i slot="default" class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,最多1张</div>
                            </el-upload>
                        </el-form-item>

                        <el-form-item label="线下场所对应的商家AppID">
                            <el-input v-model="bizStoreInfo.biz_sub_appid" placeholder="可填写与商家主体一致且已认证的公众号、小程序、APP的AppID" />
                        </el-form-item>
                    </el-form>
                </div>

                <div v-if="salesInfo.salesScenesType == 'SALES_SCENES_MP'">
                    <el-form ref="addForm" :model="mpInfo" label-width="100px">

                        <el-form-item label="服务商公众号AppID">
                            <el-input v-model="mpInfo.mpAppid" placeholder="服务商公众号APPID与商家公众号APPID，二选一必填。" />
                        </el-form-item>

                        <el-form-item label="商家公众号AppID">
                            <el-input v-model="mpInfo.mpSubAppid" placeholder="服务商公众号APPID与商家公众号APPID，二选一必填。" />
                        </el-form-item>
                        <el-form-item label="公众号页面截图">
                            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                :show-file-list="false" :on-success="mpPicsUploadSuccess">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="salesInfo.salesScenesType == 'SALES_SCENES_MINI_PROGRAM'">
                    <el-form ref="miniProgramInfo" :model="miniProgramInfo" label-width="100px">

                        <el-form-item label="服务商小程序APPID">
                            <el-input v-model="miniProgramInfo.miniProgramAppid"
                                placeholder="服务商小程序APPID与商家小程序APPID，二选一必填。" />
                        </el-form-item>

                        <el-form-item label="商家小程序APPID">
                            <el-input v-model="miniProgramInfo.miniProgramSubAppid"
                                placeholder="服务商小程序APPID与商家小程序APPID，二选一必填。" />
                        </el-form-item>
                        <el-form-item label="小程序截图">
                            <el-upload class="upload-demo" :action="BASE_URL + '/common/wxUploadImg'" :multiple="true"
                                :data="dataForm" :file-list="tempData.miniProgramPics" :on-success="miniProgramPicsSuccess"
                                :on-remove="miniProgramPicRemove" :limit="5" list-type="picture-card">
                                <i slot="default" class="el-icon-plus"></i>
                                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,最多5张</div>
                            </el-upload>

                            <div class="tips">请提供展示商品/服务的页面截图/设计稿（最多5张），若小程序未建设完善或未上线 请务必提供；</div>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="salesInfo.salesScenesType == 'SALES_SCENES_APP'">
                    <el-form ref="addForm" :model="appInfo" label-width="100px">

                        <el-form-item label="服务商应用APPID">
                            <el-input v-model="appInfo.appAppid" placeholder="服务商应用APPID与商家应用APPID，二选一必填。" />
                        </el-form-item>

                        <el-form-item label="商家应用APPID">
                            <el-input v-model="appInfo.appSubAppid" placeholder="服务商应用APPID与商家应用APPID，二选一必填。" />
                        </el-form-item>
                        <el-form-item label="APP截图">
                            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                :data="dataForm" :show-file-list="false" :on-success="appPicsUploadSuccess">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>

                <div v-if="salesInfo.salesScenesType == 'SALES_SCENES_WEB'">
                    <el-form ref="addForm" :model="webInfo" label-width="100px">

                        <el-form-item label="互联网网站域名">
                            <el-input v-model="webInfo.domain" placeholder="网站域名需ICP备案，若备案主体与申请主体不同，请上传加盖公章的网站授权函。" />
                        </el-form-item>

                        <el-form-item label="网站授权函">
                            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                :data="dataForm" :show-file-list="false" :on-success="webAuthorisationUploadSuccess">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="互联网网站对应的商家APPID">
                            <el-input v-model="webInfo.webAppid" placeholder="若备案主体与申请主体不同，请务必上传加盖公章的网站授权函。" />
                        </el-form-item>
                    </el-form>
                </div>

                <div v-if="salesInfo.salesScenesType == 'SALES_SCENES_WEWORK'">
                    <el-form ref="addForm" :model="weworkInfo" label-width="100px">

                        <el-form-item label="商家企业微信CorpID">
                            <el-input v-model="weworkInfo.subCorpId" placeholder="可填写与商家主体一致且已认证的企业微信CorpID。" />
                        </el-form-item>
                        <el-form-item label="企业微信页面截图">
                            <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/"
                                :data="dataForm" :show-file-list="false" :on-success="weworkPicsUploadSuccess">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="content" v-if="type == 4">
            <div class="item">
                <div class="title">结算规则</div>
                <el-form ref="addForm" :rules="rules" :model="addForm" label-width="100px">
                    <el-form-item label="入驻结算规则ID" prop="settlementId">
                        <el-input v-model="addForm.settlementId" />
                    </el-form-item>
                    <el-form-item label="所属行业" prop="qualificationType">
                        <el-cascader :options="options" v-model="qualificationType" @change="handleChange"></el-cascader>
                    </el-form-item>

                    <el-form-item label="特殊资质图片">
                        <el-upload class="upload-demo" :action="BASE_URL + '/common/wxUploadImg'" :multiple="true"
                            :file-list="tempData.qualifications" :on-success="qualificationsUploadSuccess" :data="dataForm"
                            :on-remove="qualificationsRemove" :limit="5" list-type="picture-card">
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,最多1张</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="优惠费率活动ID">
                        <el-input v-model="addForm.activitiesId" />
                    </el-form-item>
                    <el-form-item label="优惠费率活动值">
                        <el-input v-model="addForm.activitiesRate" />
                    </el-form-item>
                    <el-form-item label="非信用卡活动费率值">
                        <el-input v-model="addForm.debitActivitiesRate" />
                    </el-form-item>
                    <el-form-item label="信用卡活动费率值">
                        <el-input v-model="addForm.creditActivitiesRate" />
                    </el-form-item>
                    <el-form-item label="优惠费率活动补充材料">
                        <el-upload class="upload-demo" :action="BASE_URL + '/common/wxUploadImg'" :multiple="true"
                            :file-list="tempData.activitiesAdditions" :on-success="activitiesAdditionsUploadSuccess"
                            :data="dataForm" :on-remove="activitiesAdditionsRemove" :limit="5" list-type="picture-card">
                            <i slot="default" class="el-icon-plus"></i>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,最多1张</div>
                        </el-upload>

                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="content" v-if="type == 5">
            <div class="item">
                <div class="title">结算银行账户</div>
                <el-form ref="addForm" :model="addForm" :rules="rules" label-width="100px">
                    <el-form-item label="账户类型" prop="bankAccountType">
                        <el-select v-model="addForm.bankAccountType" clearable style="width: 100%;">
                            <el-option label="对公银行账户" value="BANK_ACCOUNT_TYPE_CORPORATE"></el-option>
                            <el-option label="经营者个人银行卡" value="BANK_ACCOUNT_TYPE_PERSONAL"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开户名称" prop="accountName">
                        <el-input v-model="addForm.accountName" placeholder="开户名称必须与营业执照上的“商户名称”一致" />
                    </el-form-item>
                    <el-form-item label="开户银行" prop="accountBank">
                        <el-input v-model="addForm.accountBank"
                            placeholder="17家直连银行，请根据开户银行对照表直接填写银行名;非17家直连银行，该参数请填写为“其他银行”。" />
                    </el-form-item>
                    <el-form-item label="开户银行省市编码" prop="bankAddressCode">
                        <el-input v-model="addForm.bankAddressCode" />
                    </el-form-item>
                    <el-form-item label="开户银行联行号" prop="bankBranchId">
                        <el-input v-model="addForm.bankBranchId"
                            placeholder="17家直连银行无需填写，如为其他银行，则开户银行全称（含支行）和 开户银行联行号二选一。" />
                    </el-form-item>
                    <el-form-item label="开户银行全称（含支行）" prop="bankName">
                        <el-input v-model="addForm.bankName" placeholder="17家直连银行无需填写，如为其他银行，则开户银行全称（含支行）和 开户银行联行号二选一。" />
                    </el-form-item>
                    <el-form-item label="银行账号" prop="accountNumber">
                        <el-input v-model="addForm.accountNumber" />
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="action">
            <el-button type="info" @click="back">返回</el-button>
            <el-button type="success" @click="save">保存</el-button>
        </div>
    </div>
</template>
  
<script>
import {
    BASE_URL,
    shopApplymentGetByShopId,
    shopApplymentAdd
} from "@/network/request";
import hy from "@/assets/json/hy";

export default {
    data() {
        return {
            loading: null,
            type: 0,
            title: "",
            addForm: {},
            form: {},
            BASE_URL: BASE_URL,
            businessLicenseInfo: {},            //营业执照
            idCardInfo: {},
            certificateInfo: {},                //登记证书
            financeInstitutionInfo: {},        //金融机构许可证信息
            identityInfo: {},                   //经营者/法人身份证件
            ubo_info_list: {},                   //最终受益人信息列表(UBO)
            salesInfo: {},                      //经营资料-》经营场景
            bizStoreInfo: {},                      //-线下场所场景
            storeEntrancePic: [],
            storeEntrancePicUrl: [],
            indoorPic: [],
            indoorPicUrl: [],
            mpInfo: {},
            miniProgramInfo: {},
            miniProgramPics: [],
            miniProgramPicsUrl: [],
            appInfo: {},
            webInfo: {},
            weworkInfo: {},                     //企业微信场景
            qualifications: [],
            qualificationsUrl: [],
            activitiesAdditions: [],
            activitiesAdditionsUrl: [],
            imageUrl: "",
            hy: hy,
            value: null,
            qualificationType: [],
            contactIdDocCopy: "",
            contactIdDocCopyBack: "",
            options: [],
            tempData: {
                contactPeriodBegin: null,
                contactPeriodEnd: null,
                businessAuthorizationLetter: "",
                qualifications: [],
                activitiesAdditions: [],
                storeEntrancePic: [],
                indoorPic: [],
                licenseCopy: "",
                idCardCopy: "",
                idCardNational: "",
                miniProgramPics: []
            },
            rules: {
                contactType: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                contactName: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                contactEmail: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                mobilePhone: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                licenseCopy: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                licenseNumber: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                merchantName: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                legalPerson: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                certCopy: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                certType: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                certNumber: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                companyAddress: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                periodBegin: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                periodEnd: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                merchantShortname: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                servicePhone: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                bankAccountType: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                accountName: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                accountBank: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                bankAddressCode: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                accountNumber: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                settlementId: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                qualificationType: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                bizStoreName: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                bizAddressCode: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                bizStoreAddress: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                storeEntrancePic: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                indoorPic: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                idCardCopy: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                idCardNational: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                idCardNumber: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                idCardName: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                cardPeriodBegin: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                cardPeriodEnd: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
            },
            dataForm: {
                shopId: 0,
                classify: 2
            }
        }
    },
    methods: {
        beforUpload(v) {
            if (v.size > 1020 * 1024 * 1024) {
                this.$message.warning("上传文件大小限制为1M")
                return false;
            }
            this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        idCardCopyUploadSuccess(v) {
            if (v.flag) {
                this.idCardInfo.idCardCopy = v.data.mediaId
                this.idCardInfo.idCardCopyUrl = v.data.fileName
                this.tempData.idCardCopy = this.idCardInfo.idCardCopyUrl
            } else {
                this.$message.error(v.message)
            }
        },
        idCardNationalUploadSuccess(v) {
            if (v.flag) {
                this.idCardInfo.idCardNational = v.data.mediaId
                this.idCardInfo.idCardNationalUrl = v.data.fileName
                this.tempData.idCardNational = this.idCardInfo.idCardNationalUrl
            } else {
                this.$message.error(v.message)
            }
        },
        licenseCopyUploadSuccess(v) {

            if (v.flag) {
                this.businessLicenseInfo.licenseCopy = v.data.mediaId
                this.businessLicenseInfo.licenseCopyUrl = v.data.fileName
                this.tempData.licenseCopy = this.businessLicenseInfo.licenseCopyUrl
            } else {
                this.$message.error(v.message)
            }
        },
        miniProgramPicsSuccess(v) {
            if (v.flag) {
                this.miniProgramPics.push(v.data.mediaId)
                this.miniProgramPicsUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        miniProgramPicRemove() {
            this.tempData.miniProgramPics = []
            this.miniProgramPics = []
            this.miniProgramPicsUrl = []
        },
        // 线下场所门头照片
        storeEntrancePicUploadSuccess(v) {
            if (v.flag) {
                this.storeEntrancePic.push(v.data.mediaId)
                this.storeEntrancePicUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        storeEntrancePicRemove() {
            this.tempData.storeEntrancePic = []
            this.storeEntrancePic = []
            this.storeEntrancePicUrl = []
        },
        // 线下场所内部照片
        indoorPicUploadSuccess(v) {
            if (v.flag) {
                this.indoorPic.push(v.data.mediaId)
                this.indoorPicUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        indoorPicRemove() {
            this.tempData.indoorPic = []
            this.indoorPic = []
            this.indoorPicUrl = []
        },
        // 优惠费率活动补充材料
        activitiesAdditionsUploadSuccess(v) {
            if (v.flag) {
                this.activitiesAdditions.push(v.data.mediaId)
                this.activitiesAdditionsUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        activitiesAdditionsRemove() {
            this.tempData.activitiesAdditions = []
            this.activitiesAdditions = []
            this.activitiesAdditionsUrl = []
        },
        // 特殊资质图片
        qualificationsUploadSuccess(v) {
            if (v.flag) {
                this.qualifications.push(v.data.mediaId)
                this.qualificationsUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        qualificationsRemove() {
            this.tempData.qualifications = []
            this.qualifications = []
            this.qualificationsUrl = []
        },
        // 业务办理授权函
        businessAuthSuccess(v) {
            if (v.flag) {
                this.addForm.businessAuthorizationLetter = v.data.mediaId
                this.addForm.businessAuthorizationLetterUrl = v.data.fileName
                this.tempData.businessAuthorizationLetter = this.addForm.businessAuthorizationLetterUrl
                this.qualificationsUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        // 证件正面照片
        adminIdCardSuccess(v) {
            if (v.flag) {
                this.addForm.contactIdDocCopy = v.data.mediaId
                this.addForm.contactIdDocCopyUrl = v.data.fileName
                this.contactIdDocCopy = this.addForm.contactIdDocCopyUrl
                this.qualificationsUrl.push(v.data.fileName)
            } else {
                this.$message.error(v.message)
            }
        },
        // 证件反面照片
        adminIdCardBackSuccess(v) {
            if (v.flag) {
                this.addForm.contactIdDocCopyBack = v.data.mediaId
                this.addForm.contactIdDocCopyBackUrl = v.data.fileName
                this.contactIdDocCopyBack = this.addForm.contactIdDocCopyBackUrl
            } else {
                this.$message.error(v.message)
            }
        },
        // 证件生效日期
        contactPeriodBeginChange() {
            console.log(this.tempData.contactPeriodBegin);
            let date = this.tempData.contactPeriodBegin
            let y = date.getFullYear();
            let m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
            let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
            this.addForm.contactPeriodBegin = `${y}-${m}-${d}`
        },
        // 证件失效日期
        contactPeriodEndChange() {
            let date = new Date(this.tempData.contactPeriodEnd)
            let y = date.getFullYear();
            let m = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
            let d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
            this.addForm.contactPeriodEnd = `${y}-${m}-${d}`
        },
        save() {

            this.miniProgramInfo.miniProgramPics = this.miniProgramPics
            this.miniProgramInfo.miniProgramPicsUrl = this.miniProgramPicsUrl

            this.bizStoreInfo.storeEntrancePic = this.storeEntrancePic
            this.bizStoreInfo.storeEntrancePicUrl = this.storeEntrancePicUrl
            this.bizStoreInfo.indoorPic = this.indoorPic
            this.bizStoreInfo.indoorPicUrl = this.indoorPicUrl

            this.salesInfo.miniProgramInfo = this.miniProgramInfo
            this.salesInfo.bizStoreInfo = this.bizStoreInfo
            this.addForm.salesInfo = JSON.stringify(this.salesInfo)

            this.addForm.activitiesAdditions = JSON.stringify(this.activitiesAdditions)
            this.addForm.activitiesAdditionsUrl = JSON.stringify(this.activitiesAdditionsUrl)
            this.addForm.qualifications = JSON.stringify(this.qualifications)
            this.addForm.qualificationsUrl = JSON.stringify(this.qualificationsUrl)


            this.addForm.businessLicenseInfo = JSON.stringify(this.businessLicenseInfo)
            this.identityInfo.idCardInfo = this.idCardInfo
            this.addForm.identityInfo = JSON.stringify(this.identityInfo)

            console.log(this.addForm);
            shopApplymentAdd(this.addForm).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.getData()
                    this.$message.success(data.message);
                    setTimeout(() => {
                        this.$router.go(-1);
                    }, 1000);
                } else {
                    this.$message.error(data.message);
                }
            })
        },
        getData() {
            shopApplymentGetByShopId(this.form.shopId).then(res => {
                let data = res.data
                if (data.code==200) {
                    // this.form = data.data
                    this.addForm = data.data
                    this.addForm.qualificationType != "" ? this.qualificationType = JSON.parse(this.addForm.qualificationType) : this.qualificationType = []
                    if (this.addForm.salesInfo != null && this.addForm.salesInfo != "") {
                        this.salesInfo = JSON.parse(this.addForm.salesInfo)
                        this.salesInfo.bizStoreInfo != undefined ? this.bizStoreInfo = this.salesInfo.bizStoreInfo : this.bizStoreInfo = {}         //线下场景
                        this.salesInfo.miniProgramInfo != undefined ? this.miniProgramInfo = this.salesInfo.miniProgramInfo : this.miniProgramInfo = {}     //小程序场景
                        // this.tempData.storeEntrancePic = this.bizStoreInfo.storeEntrancePicUrl
                        // this.tempData.indoorPic = this.bizStoreInfo.indoorPicUrl 
                        if (this.miniProgramInfo.miniProgramPicsUrl != undefined) {
                            let arr = this.miniProgramInfo.miniProgramPicsUrl
                            for (let i = 0; i < arr.length; i++) {
                                let obj = {}
                                obj.name = i + arr[i].split(".")[1]
                                obj.url = arr[i]
                                this.tempData.miniProgramPics.push(obj)
                            }
                        }
                        if (this.bizStoreInfo.indoorPicUrl != undefined) {
                            let arr1 = this.bizStoreInfo.indoorPicUrl
                            for (let i = 0; i < arr1.length; i++) {
                                let obj = {}
                                obj.name = i + arr1[i].split(".")[1]
                                obj.url = arr1[i]
                                this.tempData.indoorPic.push(obj)
                            }
                        }
                        if (this.bizStoreInfo.storeEntrancePicUrl != undefined) {
                            let arr2 = this.bizStoreInfo.storeEntrancePicUrl
                            for (let i = 0; i < arr2.length; i++) {
                                let obj = {}
                                obj.name = i + arr2[i].split(".")[1]
                                obj.url = arr2[i]
                                this.tempData.storeEntrancePic.push(obj)
                            }
                        }




                        this.miniProgramPics = this.miniProgramInfo.miniProgramPics
                        this.miniProgramPicsUrl = this.miniProgramInfo.miniProgramPicsUrl

                        this.storeEntrancePic = this.bizStoreInfo.storeEntrancePic
                        this.storeEntrancePicUrl = this.bizStoreInfo.storeEntrancePicUrl

                        this.indoorPic = this.bizStoreInfo.indoorPic
                        this.indoorPicUrl = this.bizStoreInfo.indoorPicUrl
                    }

                    if (this.addForm.businessLicenseInfo != null) {
                        this.businessLicenseInfo = JSON.parse(this.addForm.businessLicenseInfo)
                        this.businessLicenseInfo != null ? this.tempData.licenseCopy = this.businessLicenseInfo.licenseCopyUrl : this.tempData.licenseCopy = ""
                    }

                    if (this.addForm.identityInfo != null) {
                        this.identityInfo = JSON.parse(this.addForm.identityInfo)
                        this.idCardInfo = this.identityInfo.idCardInfo
                        this.idCardInfo != null ? this.tempData.idCardCopy = this.idCardInfo.idCardCopyUrl : this.tempData.idCardCopy = ""
                        this.idCardInfo != null ? this.tempData.idCardNational = this.idCardInfo.idCardNationalUrl : this.tempData.idCardNational = ""
                    }
                    if (this.addForm.qualificationsUrl != "") {
                        let arr3 = JSON.parse(this.addForm.qualificationsUrl)
                        for (let i = 0; i < arr3.length; i++) {
                            let obj = {}
                            obj.name = i + arr3[i].split(".")[1]
                            obj.url = arr3[i]
                            this.tempData.qualifications.push(obj)
                        }
                        this.qualifications = JSON.parse(this.addForm.qualifications)
                        this.qualificationsUrl = JSON.parse(this.addForm.qualificationsUrl)
                    }
                    if (this.addForm.activitiesAdditionsUrl != null) {
                        let arr4 = JSON.parse(this.addForm.activitiesAdditionsUrl)
                        for (let i = 0; i < arr4.length; i++) {
                            let obj = {}
                            obj.name = i + arr4[i].split(".")[1]
                            obj.url = arr4[i]
                            this.tempData.activitiesAdditions.push(obj)
                        }
                        this.activitiesAdditions = JSON.parse(this.addForm.activitiesAdditions)
                        this.activitiesAdditionsUrl = JSON.parse(this.addForm.activitiesAdditionsUrl)
                    }

                    this.contactIdDocCopy = this.addForm.contactIdDocCopyUrl
                    this.contactIdDocCopyBack = this.addForm.contactIdDocCopyBackUrl
                    this.tempData.businessAuthorizationLetter = this.addForm.businessAuthorizationLetterUrl
                    this.tempData.contactPeriodBegin = new Date(this.addForm.contactPeriodBegin)
                    this.tempData.contactPeriodEnd = new Date(this.addForm.contactPeriodEnd)
                    console.log(this.addForm);
                }
            })
        },
        handleChange(v) {
            this.addForm.qualificationType = JSON.stringify(v)
            // console.log(v);
        },
        back() {
            this.$router.go(-1)
        },
        handleAvatarSuccess() { },
        gotoAdd() {
            this.$router.push("/shopAdd")
        }
    },
    created() {
        this.type = this.$route.params.id
        this.addForm.shopId = window.localStorage.getItem("shopId");
        this.form.shopId = window.localStorage.getItem("shopId");
        this.dataForm.shopId = this.form.shopId
        this.getData()

        // console.log(this.form);
        if (this.type == 1) {
            this.title = "超级管理员信息"
        } else if (this.type == 2) {
            this.title = "主体资料"
        } else if (this.type == 3) {
            this.title = "经营资料"
        } else if (this.type == 4) {
            this.title = "结算规则"
        } else if (this.type == 5) {
            this.title = "结算银行账户"
        }
        // console.log(this.hy);
        let arr = []
        for (let i = 0; i < this.hy.length; i++) {
            let obj = {}
            obj.label = this.hy[i].big_industry_info.big_industry_name
            obj.value = this.hy[i].big_industry_info.big_industry_name
            obj.children = []
            for (let j = 0; j < this.hy[i].industry_category_list.length; j++) {
                let childObj = {}
                childObj.label = this.hy[i].industry_category_list[j].industry_category_info.category_name
                childObj.value = this.hy[i].industry_category_list[j].industry_category_info.category_name
                obj.children.push(childObj)
            }
            arr.push(obj)
            // console.log(this.hy[i]);
        }
        this.options = arr
        // console.log(this.options);
    }
}
</script>
  
<style lang="less">
.add-subject {
    background-color: #fff;
    padding: 20px;
    line-height: 1;

    // .el-upload--picture-card {
    //     width: 100px !important;
    //     height: 100px !important;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }

    .titlee {
        font-weight: bold;
        font-size: 20px;
    }

    .tips {
        font-size: 12px;
        color: #999;
        margin-top: 10px;
    }

    .content {
        margin-top: 20px;

        .title {
            margin: 30px 0;
            padding-left: 10px;
            border-left: red solid 4px;
        }

        .headline {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 8px;
        }

        .item {
            .avatar-uploader .el-upload {
                border: 1px dashed #d9d9d9;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
            }

            .avatar-uploader .el-upload:hover {
                border-color: #409EFF;
            }

            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 80px;
                height: 80px;
                line-height: 80px;
                text-align: center;
            }

            .avatar {
                width: 80px;
                height: 80px;
                display: block;
            }
        }

        .tips {
            line-height: 1.5;
        }

    }

    .action {
        margin-top: 20px;
    }
}
</style>