<template>
  <div class="login">
    <div class="left">
      <img src="@/assets/images/login_bg.png" alt="" />
    </div>
    <div class="right">
      <!-- <h2 style=" margin-bottom: 20px;">
        呼和浩特供电分公司
      </h2> -->
      <h2 style="font-size: 40px; margin-bottom: 100px; color: #333333">
        欢迎使用惠来啦智慧营销系统
      </h2>
      <div class="form">
        <div class="item">
          <span>账&emsp;号：</span><el-input v-model="loginData.phone" placeholder="请输入手机号"></el-input>
        </div>
        <div class="item">
          <span>密&emsp;码：</span><el-input show-password v-model="loginData.password" placeholder="请输入密码"></el-input>
        </div>
        <div class="action">
          <div @click="handlerLogin">
            <p>登&ensp;录</p>
          </div>
        </div>
        <div class="sign">
          <!-- <div class="btn" @click="gotoRegister"> 没有账号？ <span style="color: red;">立即注册！</span> </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/network/request";
export default {
  data() {
    return {
      loginData: {
        phone: "",
        password: "",
      },
    };
  },
  methods: {
    gotoRegister() {
      this.$router.push("/register")
    },
    handlerLogin() {
      // this.$router.push("/");
      login(this.loginData).then((res) => {
        // console.log(res);
        let data = res.data;
        if (data.code==200) {
          this.$message.success(data.message);
          window.localStorage.setItem(
            "token",
            data.data.token
          );
          window.localStorage.setItem(
            "shopList",
            JSON.stringify(data.data.shopList)
          );
          window.localStorage.setItem(
            "shopId",
            data.data.shopList[0].id
          );
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(data.data.userInfo)
          );
          window.localStorage.setItem(
            "permission",
            JSON.stringify(data.data.permission)
          );
          this.$router.push("/");
        } else {
          this.$message.error(data.message);
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.login {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1200px;

  .left {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;

    img {
      width: 50vw;
      height: auto;
    }
  }

  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      text-align: center;
      font-size: 40px;
    }
  }

  .form {
    width: 400px;

    .item {
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        width: 120px;
        font-size: 18px;
      }

      .el-input {
        font-size: 18px;
      }
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;

      div {
        width: 100%;
        border-radius: 20px;
        cursor: pointer;
        background: linear-gradient(to bottom right, #1a54ed, #0f30ad);
        padding: 10px 100px;
        color: white;

        p {
          text-align: center;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .sign {
      margin-top: 20px;
      text-align: right;

      .btn {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}
</style>