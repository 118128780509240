<template>
  <div class="shop">
    <div>
      <el-button type="success" @click="gotoAdd">微信入驻</el-button>
      <!-- <el-button type="success" @click="gotoAddZfb">支付宝入驻</el-button> -->
    </div>
    <div class="content">
      <div class="item">
        <div class="title">门店ID：</div>
        <div class="cnt">{{ info.shopId }}</div>
      </div>
      <div class="item">
        <div class="title">门店名称：</div>
        <div class="cnt">{{ info.shopName }}</div>
      </div>
      <div class="item">
        <div class="title">企业logo：</div>
        <div class="cnt"><el-avatar size="large" :src="info.logoUrl"></el-avatar></div>
      </div>
      <div class="item">
        <div class="title">行业：</div>
        <div class="cnt">{{ info.industry }}</div>
      </div>
      <div class="item">
        <div class="title">分类：</div>
        <div class="cnt">{{ info.sortNickname }}</div>
      </div>
      <div class="item">
        <div class="title">公告/简介：</div>
        <div class="cnt">{{ info.shopInstruction }}</div>
      </div>
      <div class="item">
        <div class="title">管理员：</div>
        <div class="cnt">{{ info.adminName }}</div>
      </div>
      <div class="item">
        <div class="title">联系电话：</div>
        <div class="cnt">{{ info.adminPhone }}</div>
      </div>
      <div class="item">
        <div class="title">门店电话：</div>
        <div class="cnt">{{ info.shopPhone }}</div>
      </div>
      <div class="item">
        <div class="title">门店地址：</div>
        <div class="cnt">{{ info.shopAddress }}</div>
      </div>
      <div class="item">
        <div class="title">电子邮箱：</div>
        <div class="cnt">{{ info.adminEmail }}</div>
      </div>
      <div class="item">
        <div class="title">店铺照片：</div>
        <div class="cnt" v-if="info.banners != ''">
          <img class="img" :src="item" alt="" v-for="(item, index) in info.banners.split('__')" :key="index">
        </div>
      </div>
      <div class="item">
        <div class="title">收款码：</div>
        <div class="cnt"> <el-button type="success" @click="createQr" size="small">查看</el-button> </div>
      </div>
      <div class="item">
        <div class="title">分享码：</div>
        <div class="cnt"> <el-button type="success" @click="createShareQr" size="small">查看</el-button> </div>
      </div>
    </div>
    <el-button type="primary" @click="edit">修改</el-button>

    <el-dialog title="店铺码" :visible.sync="qrVisible" width="900px">
      <div style="display: flex;flex-direction: row;justify-content: center;">
        <div class="qr-content" ref="imageWrapper">
          <QrCode id="qrcode" :width="310" :height="310" :url="url"></QrCode>
          <div class="code">NO:{{ this.shopId }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrVisible = false">取 消</el-button>
        <el-button type="primary" @click="toImg">下载</el-button>
      </span>
    </el-dialog>

    <el-dialog title="店铺码" :visible.sync="qrShareVisible" width="900px">
      <div style="display: flex;justify-content: center;" ref="shareImg">
        <QrCode id="qrcode" :width="310" :height="310" :url="url"></QrCode>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrShareVisible = false">取 消</el-button>
        <!-- <el-button type="primary" @click="downShare">下载</el-button> -->
      </span>
    </el-dialog>



    <el-dialog title="修改" :visible.sync="editVisible" width="60%">
      <div class="edit-content">
        <el-form ref="editForm" :rules="rules" :model="form" label-width="100px">
          <el-form-item label="门店名称" prop="shopName">
            <el-input v-model="form.shopName"></el-input>
          </el-form-item>
          <el-form-item label="企业logo">
            <el-upload class="avatar-uploader" :data="uploadData" :headers="headers"
              :action="BASE_URL + '/biz/common/uploadImg'" :show-file-list="false" :on-success="uploadSuccess"
              :before-upload="logoBefore">
              <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="行业" prop="industry">
            <el-cascader v-model="industry" :options="options" @change="industryChange"
              style="width: 100%;"></el-cascader>
          </el-form-item>
          <el-form-item label="分类">
            <el-cascader v-model="sortFull" :props="{ 'expandTrigger': 'hover', 'value': 'id', 'label': 'nickname' }"
              :options="sortList" @change="sortChange" style="width: 100%;"></el-cascader>
          </el-form-item>
          <el-form-item label="管理员姓名" prop="adminName">
            <el-input v-model="form.adminName"></el-input>
          </el-form-item>
          <el-form-item label="管理员电话" prop="adminPhone">
            <el-input v-model="form.adminPhone"></el-input>
          </el-form-item>
          <el-form-item label="电子邮箱">
            <el-input v-model="form.adminEmail"></el-input>
          </el-form-item>
          <el-form-item label="门店地址" prop="shopAddress">
            <el-input v-model="form.shopAddress"></el-input>
          </el-form-item>
          <el-form-item label="门店电话" prop="shopPhone">
            <el-input v-model="form.shopPhone"></el-input>
          </el-form-item>
          <el-form-item label="公告/简介" prop="shopInstruction">
            <el-input v-model="form.shopInstruction"></el-input>
          </el-form-item>
          <el-form-item label="店铺图片" prop="shopInstruction">
            <el-upload class="upload-demo" :data="uploadData" :headers="headers" :action="BASE_URL + '/biz/common/uploadImg'"
              :multiple="true" :file-list="fileList" :on-remove="onRemove" :on-success="shopUploadSuccess"
              :before-upload="shopBefore" :limit="5" list-type="picture-card">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,大小限制为1M,最多5张</div>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item label="登录密码" prop="loginPwd">
            <el-input v-model="form.loginPwd" placeholder="密码由8-20位字母+数字+特殊符号组成，区分大小写"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="loginPwdAgain">
            <el-input v-model="form.loginPwdaAain"></el-input>
          </el-form-item>
          <el-form-item label="手机验证码" prop="code">
            <div class="code" style="display: flex;flex-direction: row;justify-content: space-between;">
              <el-input v-model="form.code" style="width: 100px;"></el-input>
              <el-button type="success" style="margin-left: 30px;">获取验证码</el-button>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisible = false">取 消</el-button>
        <el-button type="primary" @click="editConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import hy from "@/assets/json/hy";
import { BASE_URL, getShopInfo, registerEdit, shopSortList } from "@/network/request";
import QrCode from '@/components/QrCode.vue';
export default {
  components: { QrCode },
  data() {
    return {
      loading: null,
      BASE_URL: BASE_URL,
      editVisible: false,
      qrVisible: false,
      qrShareVisible: false,
      shopId: null,
      uploadData: {
        classify: null,
        shopId: null,
      },
      headers: {},
      url: "",
      url1: "",
      info: {
        banners: ""
      },
      form: {},   //edit表单
      industry: [],
      sortList: [],
      tempUrl: "",
      fileList: [],
      fileTempList: [],
      hy: hy,
      sortFull: [],
      rules: {
        shopName: [
          { required: true, message: '请输入门店名称', trigger: 'blur' }
        ],
        industry: [
          { required: true, message: '请选择行业', trigger: 'change' }
        ],
        adminName: [
          { required: true, message: '请输入管理员姓名', trigger: 'blur' }
        ],
        adminPhone: [
          { required: true, message: '请输入管理员电话', trigger: 'blur' },
          { length: 11, message: '手机号长度为11位', trigger: 'blur' }
        ],
        shopAddress: [
          { required: true, message: '请输入门店地址', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    onRemove(v) {
      for (let i = 0; i < this.fileTempList.length; i++) {
        if (this.fileTempList[i].url == v.url) {
          this.fileTempList.splice(i, 1)
        }
      }
    },
    toImg() {
      html2canvas(this.$refs.imageWrapper, {
        allowTaint: true, //开启跨域
        useCORS: true,
      }).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        // console.log(dataURL);
        var objimg = dataURL
        //这里objimg 表示图片显示的路径  一般都是data:image/jpeg;base64,iVBORw0KGgoAAAANS
        const byteCharacters = atob(objimg.split(',')[1])
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        var blobs = 'image/png'
        this.downLong = false
        let url = window.URL.createObjectURL(
          new Blob([byteArray], { type: blobs })
        );
        let link = document.createElement("a"); //创建a标签
        link.style.display = "none"; //将a标签隐藏
        link.href = url; //给a标签添加下载链接
        link.setAttribute("download", 'wxpay-code');
        document.body.appendChild(link);
        link.click(); //执行a标签
      });
    },
    shopUploadSuccess(v) {
      let data = v.data
      let obj = {}
      obj.name = data.split(".")[data.split(".").length - 1]
      obj.url = data
      this.fileTempList.push(obj)
      this.loading.close()
    },
    shopBefore(v) {
      if (v.size > 1020 * 1024 * 1024) {
        this.$message.warning("上传文件大小限制为1M")
        return false;
      }
      this.uploadData.classify = 3
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    logoBefore(v) {
      if (v.size > 1020 * 1024 * 1024) {
        this.$message.warning("上传文件大小限制为1M")
        return false;
      }
      this.uploadData.classify = 1
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    getSort() {
      shopSortList().then(res => {
        let data = res.data
        if (data.code==200) {
          this.sortList = data.data
        }
      })
    },
    createQr() {
      this.url = "http://beect.cn/pay/shop?shopId=" + this.shopId
      this.qrVisible = true
    },
    createShareQr() {
      this.url = `http://beect.cn/pay/merchant_home?id=${this.shopId}&uid=${this.shopId}&presenterType=1`
      this.qrShareVisible = true
    },
    editConfirm() {
      this.tempUrl = ""
      this.fileTempList.forEach(k => {
        this.tempUrl += `${k.url}__`
      })
      this.form.banners = this.tempUrl.substring(0, this.tempUrl.length - 2)
      this.$delete(this.form, "sortNickname"); 
      // console.log(this.form);
      registerEdit(this.form).then(res => {
        let data = res.data
        if (data.code==200) {
          this.getData()
          this.$message.success(data.message);
        } else {
          this.$message.error(data.message);
        }
      })
      this.editVisible = false
    },
    uploadSuccess(v) {
      this.loading.close()
      this.form.logoUrl = v.data
    },
    edit() {
      this.editVisible = true
    },
    sortChange(v) {
      this.form.sortFull = JSON.stringify(v)
      this.form.sort = v[v.length - 1]
      // console.log(this.sortFull);
    },
    industryChange(v) {
      this.form.industry = v.join("_")
    },

    getData() {
      this.fileList = []
      this.fileTempList = []
      getShopInfo(this.shopId).then(res => {
        let data = res.data
        if (data.code==200) {
          this.info = data.data
          this.form = { ...this.info }
          this.industry = this.info.industry.split("_")
          let temp = this.info.banners.split("__")
          for (let i = 0; i < temp.length; i++) {
            let obj = {}
            obj.name = temp[i].split(".")[temp[i].split(".").length - 1]
            obj.url = temp[i]
            this.fileList.push(obj)
          }
          this.fileTempList = this.fileList
          this.sortFull = JSON.parse(this.form.sortFull)
        }
      })
    },
    gotoAdd() {
      this.$router.push("/shopAdd")
    },
    gotoAddZfb() {
      this.$router.push("/zfbAdd")
    },
  },
  created() {
    this.headers.token = window.localStorage.getItem("token");
    this.shopId = window.localStorage.getItem("shopId");
    this.uploadData.shopId = this.shopId
    this.getData()
    this.getSort()
    let arr = []
    for (let i = 0; i < this.hy.length; i++) {
      let obj = {}
      obj.label = this.hy[i].big_industry_info.big_industry_name
      obj.value = this.hy[i].big_industry_info.big_industry_name
      obj.children = []
      for (let j = 0; j < this.hy[i].industry_category_list.length; j++) {
        let childObj = {}
        childObj.label = this.hy[i].industry_category_list[j].industry_category_info.category_name
        childObj.value = this.hy[i].industry_category_list[j].industry_category_info.category_name
        obj.children.push(childObj)
      }
      arr.push(obj)
      // console.log(this.hy[i]);
    }
    this.options = arr
  }
}
</script>

<style lang="less">
.shop {
  background-color: #fff;
  padding: 20px;

  .content {
    margin-top: 20px;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 20px 0;

      .title {
        width: 100px;
        font-size: 18px;
        word-break: break-all;
        text-align: right;
      }

      .cnt {
        font-size: 18px;

        .img {
          width: 90px;
          height: 60px;
          margin-right: 10px;
        }
      }
    }
  }

  .qr-content {
    width: 544px;
    height: 668px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/images/code_bg.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 50px;
    box-sizing: border-box;
    position: relative;

    .code {
      font-size: 14px;
      color: #126640;
      position: absolute;
      bottom: 42px;
      right: 46px;
    }
  }

  .edit-content {
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    .avatar {
      width: 80px;
      height: 80px;
      display: block;
    }
  }
}
</style>