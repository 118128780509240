<template>
    <div class="receipt">
        <div class="header">小票设置</div>
        <div class="row">
            <div class="label">打印机选择：</div>
            <div class="cnt">
                <el-select v-model="printerInfo.index" placeholder="请选择" @change="printChange">
                    <el-option v-for="item in list" :key="item.index" :label="item.name" :value="item.index">
                    </el-option>
                </el-select>
            </div>
            <div class="label" style="margin-left: 20px;">纸张选择：</div>
            <div class="cnt">
                <el-select v-model="printerInfo.pageSize" placeholder="请选择">
                    <el-option v-for="(item, index) in pageSizeList" :key="index" :label="item" :value="item">
                    </el-option>
                </el-select>
            </div>
            <el-button type="primary" style="margin-left: 10px;" @click="confirm">设置</el-button>
        </div>
        <div class="title">样式</div>
        <NoteTemp :item="info" ref="temp"></NoteTemp>
        <el-button @click="print" style="margin-top: 20px;">打印预览</el-button>
    </div>
</template>

<script>
import { getLodop } from '@/assets/js/LodopFuncs'
import NoteTemp from "@/components/NoteTemp.vue";
export default {
    components: {
        NoteTemp
    },
    data() {
        return {
            noteWidth: null,
            info: {
                amountDiscount: 100.00,
                amountReal: 200.00,
                amountTotal: 300.00,
                couponInfo: "300-100优惠券x1张",
                orderId: "20231119160414247100006",
                timeCreate: "2023-11-19 18:02:06"
            },
            list: [],
            pageSizeList: [],
            LODOP: null,
            printerInfo: {
                index: null,
                pageSize: "",
            }
        }
    },
    methods: {
        confirm() {
            window.localStorage.setItem("printerInfo", JSON.stringify(this.printerInfo))
            this.LODOP.SET_PRINTER_INDEXA(this.printerInfo.index)
        },
        printChange() {
            this.getPageSize()
        },
        print() {
            console.log(this.printerInfo);
            this.LODOP.PRINT_INIT("打印任务名");
            let pageWidth = parseInt(this.printerInfo.pageSize.split('(')[0] + "0")
            this.LODOP.SET_PRINT_STYLEA("FontSize", "12");
            this.LODOP.SET_PRINT_STYLEA("FontName", "黑体");
            this.LODOP.SET_PRINT_PAGESIZE(3, pageWidth + "mm", 0, "")
            this.LODOP.ADD_PRINT_TEXT(0, 0, "100%", "6mm", `订单号：`)
            this.LODOP.ADD_PRINT_TEXT(0, "11mm", "100%", "6mm", `${this.info.orderId}`)
            this.LODOP.ADD_PRINT_TEXT("5mm", 0, "100%", "6mm", `订单时间：`)
            this.LODOP.ADD_PRINT_TEXT("5mm", "16mm", "100%", "6mm", `${this.info.timeCreate}`)
            if (pageWidth == 58) {
                this.LODOP.SET_PRINT_STYLEA(2, "FontSize", "8");
                this.LODOP.SET_PRINT_STYLEA(4, "FontSize", "8");
            }
            this.LODOP.ADD_PRINT_TEXT("10mm", 0, "100%", "6mm", `账单金额：${this.info.amountTotal}元`)
            this.LODOP.ADD_PRINT_TEXT("15mm", 0, "100%", "6mm", `优惠金额：${this.info.amountDiscount}元`)
            this.LODOP.ADD_PRINT_TEXT("20mm", 0, "100%", "6mm", `实收金额：${this.info.amountReal}元`)
            if (this.info.couponInfo) {
                this.LODOP.ADD_PRINT_TEXT("25mm", 0, "100%", "12mm", `使用优惠：${this.info.couponInfo}`)
            }
            // 预览
            this.LODOP.PREVIEW();
        },
        getPageSize() {
            if (this.printerInfo) {
                let pageSize = this.LODOP.GET_PAGESIZES_LIST(this.printerInfo.index, "\n")
                this.pageSizeList = pageSize.split("\n")
            }
        }
    },
    mounted() {
        this.LODOP = getLodop()
        let tempPrint = JSON.parse(window.localStorage.getItem("printerInfo"))
        if (tempPrint != undefined) {
            this.printerInfo = tempPrint
        }
        let num = this.LODOP.GET_PRINTER_COUNT()
        for (let i = 0; i < num; i++) {
            let obj = {}
            obj.index = i
            obj.name = this.LODOP.GET_PRINTER_NAME(i)
            this.list.push(obj)
        }
        this.getPageSize()
    }
}
</script>

<style lang="less" scoped>
.receipt {
    background-color: #fff;
    padding: 20px;

    .header {
        font-weight: bold;
        border-left: 4px solid #000;
        font-size: 18px;
        padding-left: 10px;
    }

    .title {
        font-weight: bold;
        margin: 10px 0;
    }

    .row {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}
</style>