import { render, staticRenderFns } from "./Statement.vue?vue&type=template&id=4c66d56c&scoped=true&"
import script from "./Statement.vue?vue&type=script&lang=js&"
export * from "./Statement.vue?vue&type=script&lang=js&"
import style0 from "./Statement.vue?vue&type=style&index=0&id=4c66d56c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c66d56c",
  null
  
)

export default component.exports