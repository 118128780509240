<template>
  <div class="index">
    <div class="row">
      <!-- 营业额 -->
      <div ref="turnover1" style="width: 800px; height: 300px;"></div>
      <div ref="turnover2" style="width: 800px; height: 300px;"></div>
    </div>
    <div class="row">
      <!-- 订单量 -->
      <div ref="orderAmount1" style="width: 800px; height: 300px;"></div>
      <div ref="orderAmount2" style="width: 800px; height: 300px;"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import { getOrderAmountDay, getOrderAmountHour, getTurnoverDay, getTurnoverHour } from '@/network/request'
export default {
  data() {
    return {
      shopId: null,
      turnover1Chart: null,
      turnover2Chart: null,
      orderAmount1Chart: null,
      orderAmount2Chart: null,
      chart1x: [],
      chart1y: [],
      chart2x: [],
      chart2y: [],
      chart3x: [],
      chart3y: [],
      chart4x: [],
      chart4y: [],
    }
  },
  methods: {
    async getByHour() {

      let res1 = await getTurnoverDay(this.shopId);
      if (res1.data.code==200) {
        let arr2 = res1.data.data
        for (let i = 0; i < arr2.length; i++) {
          this.chart1x.push(arr2[i].timeOfDay)
          this.chart1y.push(arr2[i].sum)
        }
      }

      let res2 = await getTurnoverHour(this.shopId);
      if (res2.data.code==200) {
        let arr2 = res2.data.data
        for (let i = 0; i < arr2.length; i++) {
          this.chart2x.push(arr2[i].timeOfHour)
          this.chart2y.push(arr2[i].sum)
        }
      }

      let res3 = await getOrderAmountDay(this.shopId);
      if (res3.data.code==200) {
        let arr2 = res3.data.data
        for (let i = 0; i < arr2.length; i++) {
          this.chart3x.push(arr2[i].timeOfDay)
          this.chart3y.push(arr2[i].count)
        }
      }


      let res4 = await getOrderAmountHour(this.shopId);
      if (res4.data.code==200) {
        let arr2 = res4.data.data
        for (let i = 0; i < arr2.length; i++) {
          this.chart4x.push(arr2[i].timeOfHour)
          this.chart4y.push(arr2[i].count)
        }
      }

      await this.drawByMin()
    },
    async drawByMin() {
      let config1 = {
        title: { text: "营业额（月/每天）" },
        tooltip: {},
        xAxis: {
          data: this.chart1x,
        },
        yAxis: {},
        series: [
          {
            name: "营业额",
            type: "bar",
            data: this.chart1y,
          },
        ],
      };
      let config2 = {
        title: { text: "营业额（天/每小时）" },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.chart2x
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.chart2y,
            type: 'line'
          }
        ]
      };
      let config3 = {
        title: { text: "订单量（月/每天）" },
        tooltip: {},
        xAxis: {
          data: this.chart3x,
        },
        yAxis: {},
        series: [
          {
            name: "订单量",
            type: "bar",
            data: this.chart3y,
          },
        ],
      };
      let config4 = {
        title: { text: "订单量（天/每小时）" },
        tooltip: {},
        xAxis: {
          type: 'category',
          data: this.chart4x,
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.chart4y,
            type: 'line'
          }
        ]
      };
      this.turnover2Chart.setOption(config2);
      this.orderAmount2Chart.setOption(config4);
      this.turnover1Chart.setOption(config1);
      this.orderAmount1Chart.setOption(config3);
    }
  },
  mounted() {
    this.turnover1Chart = echarts.init(this.$refs.turnover1);
    this.turnover2Chart = echarts.init(this.$refs.turnover2);
    this.orderAmount1Chart = echarts.init(this.$refs.orderAmount1);
    this.orderAmount2Chart = echarts.init(this.$refs.orderAmount2);
    this.getByHour()
    // 配置参数

    // 设置参数

  },
  created() {
    this.shopId = window.localStorage.getItem("shopId")
  }
}
</script>

<style lang="less" scoped>
.index {
  .row {
    display: flex;
    flex-direction: row;
  }
}
</style>