<template>
    <div class="employee">
        <div class="header">
            <el-button type="success" size="small" @click="add">添加</el-button>
        </div>
        <el-table v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" :data="list"
            border style="width: 100%">

            <el-table-column prop="nickname" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="账号" align="center">
            </el-table-column>
            <el-table-column prop="role" label="职务" align="center">
            </el-table-column>
            <el-table-column fixed="right" prop="action" label="操作" width="280px" align="center">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="primary" plain size="small">编辑</el-button>
                    <el-button type="danger" plain size="small" style="margin-left=20px"
                        @click="del(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="custom-page">
            <el-pagination layout="total,prev, pager, next" :total="total" @current-change="pageChange">
            </el-pagination>
        </div>
        <el-dialog :title="title" :visible="visible" width="30%">
            <div class="add-content">
                <el-form ref="addForm" :model="addForm" :rules="rules" label-width="80px">
                    <el-form-item label="姓名" prop="nickname">
                        <el-input v-model="addForm.nickname" placeholder="请输入姓名" style="width: 100% ;"></el-input>
                    </el-form-item>
                    <el-form-item label="账号" prop="phone">
                        <el-input v-model="addForm.phone" placeholder="账号为手机号" style="width: 100% ;"></el-input>
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input type="password" v-model="addForm.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="设置角色">
                        <el-select v-model="temp.rid" placeholder="请选择要设置的角色" style="width: 100% ;">
                            <el-option :label="item.nickname" :value="item.rid" v-for="(item) in roleList"
                                :key="item.nickname"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button type="primary" @click="confirmHandler">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import { getRoleList, employeeList, employeeAddOrSave, employeeDel } from "@/network/request";
export default {
    data() {
        return {
            visible: false,
            title: "修改",
            loading: false,
            addForm: {
                nickname: "",
                password: "",
                phone: "",
                shopId: null,
                rid: null,
                id: null,
            },
            getArg: {
                page: 1,
                size: 10,
                content: ""
            },
            total: 0,
            list: [],
            roleList: [],
            shopId: null,
            temp: {
                rid: null,
                pid: null
            },
            rules: {
                nickname: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '必填', trigger: 'blur' }
                ],
                rid: [
                    { required: true, message: '必填', trigger: 'change' }
                ],
            }
        }
    },
    methods: {
        edit(v) {
            this.addForm = {}
            this.visible = true
            this.addForm.id = v.id
            this.addForm.nickname = v.nickname
            this.addForm.phone = v.phone
            this.temp.rid = v.rid
            this.temp.pid = v.pid
        },
        add() {
            this.addForm = {}
            this.temp = {}
            this.visible = true
        },
        del(v) {
            this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                employeeDel(v.id).then((res) => {
                    this.$message({
                        type: "success",
                        message: res.data.message,
                    });
                    this.getData();
                });
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            });
        },
        confirmHandler() {
            this.addForm.rid = this.temp.rid
            this.addForm.shopId = this.shopId
            employeeAddOrSave(this.addForm).then(res => {
                this.visible = false
                let data = res.data
                if (data.code==200) {
                    this.$message.success(data.message)
                    this.getData()
                } else {
                    this.$message.error(data.message)
                }
            })
        },
        pageChange() { },
        getData() {
            this.getArg.content=this.shopId
            employeeList(this.getArg).then(res => {
                let data = res.data
                if (data.code==200) {
                    this.list = data.data.data
                    this.total = data.data.total
                }
            })
        },
        getRole() {
            getRoleList().then(res => {
                let data = res.data
                if (data.code==200) {
                    this.roleList = data.data
                }
            })
        }
    },
    created() {
        this.shopId = parseInt(window.localStorage.getItem("shopId"))
        this.getRole()
        this.getData()
    }
}
</script>
  
<style lang="less" scoped>
.employee {

    background-color: #fff;
    padding: 20px;

    .header {
        margin-bottom: 20px;
    }

    .custom-page {
        margin-top: 20px;
        text-align: center;
    }
}
</style>