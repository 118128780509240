<template>
	<div>
		<div id="qrcode"></div>
	</div>
</template>
 
<script>
	import QRCode from "qrcodejs2"
	export default {
		name: 'test',
		mounted() {
			this.qrcode();
		},
		props: {
			width: {
				type: Number,
				default () {
					return 200
				}
			},
			height: {
				type: Number,
				default () {
					return 200
				}
			},
			// 二维码地址
			url: {
				type: String,
				default () {
					return ''
				}
			}
		},
		methods: {
			qrcode() {
				new QRCode('qrcode', {
					width: this.width,
					height: this.height,
					text: this.url,
					colorDark: "#000",
					colorLight: "#fff",
					scale:2,
					dpi:3
				})
                
			},
		}
	}
</script>
<style scoped>
	#qrcode {
		display: inline-block;
	}
 
	#qrcode img {
		width: 132px;
		height: 132px;
		background-color: #fff;
		padding: 6px;
	}
</style>